import { AccessTypeCodeEnum, DeviceClassTypeCodeEnum, PersonTypeCodeEnum } from '../data/graphql/queries/enums';
import LocalStorageUtils from './localStorage.functions';

export const getAccessTypeByCode = (code = '') => {
  const types = {
    NC: 'Unknown',
    O: 'Owner',
    R: 'Resident',
    G: 'Guest',
    P: 'Pin Code Entry',
    PA: 'Property Account',
    BA: 'Building Account',
  };

  return types[code] || '';
};

export const getPersonTypeByCode = (code = '') => {
  const types = {
    NC: 'Unknown',
    T: 'Tenant',
    R: 'Resident',
    V: 'Vendor',
    E: 'Employee',
    C: 'Contractor',
    G: 'Guest',
  };

  return types[code] || '';
};

export const getResidentTypeByCode = (code = '') => {
  const types = {
    NC: 'Unknown',
    G: 'Guarantor',
    HOH: 'Head of Household',
    CHOH: 'Co-Head of Household',
    AR: 'Adult Resident',
    CR: 'Child Resident',
  };

  return types[code] || '';
};

const accessTypeIdByCode = (code: AccessTypeCodeEnum) => {
  const types = LocalStorageUtils.getItem('accessTypes');
  const type = types?.byCode[code];

  return type ? type.id : -1;
};

const accessTypeCodeById = (id: number) => {
  const types = LocalStorageUtils.getItem('accessTypes');
  const type = types?.byId[id];

  return type ? type.code : AccessTypeCodeEnum.UNKNOWN;
};

const classTypeIdByCode = (code: DeviceClassTypeCodeEnum) => {
  const types = LocalStorageUtils.getItem('deviceClassTypes');

  const type = types?.byCode[code];
  return type ? type.id : -1;
};

const classTypeIdsByCodes = (codes: DeviceClassTypeCodeEnum[]) => {
  const types = LocalStorageUtils.getItem('deviceClassTypes');

  return codes.map((code) => {
    const type = types?.byCode[code];
    return type ? type.id : -1;
  });
};

const personTypeCodeById = (personTypeId: number) => {
  const types = LocalStorageUtils.getItem('personTypes');

  const type = types?.byId[personTypeId];
  return type ? type.code : 'NC';
};

const TypesUtils = {
  getAccessTypeByCode,
  getPersonTypeByCode,
  getResidentTypeByCode,
  accessTypeIdByCode,
  deviceClass: {
    idByCode: classTypeIdByCode,
    idsByCodes: classTypeIdsByCodes,
  },
  person: {
    codeById: personTypeCodeById,
  },
  access: {
    idByCode: accessTypeIdByCode,
    codeById: accessTypeCodeById,
  },
};

export default TypesUtils;
