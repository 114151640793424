export type ApthubAccessScheduleType = 'TEMPORARY' | 'RECURRING' | 'ALWAYS';

export enum DeviceMakerEnum {
  UNKNOWN = 'UNKNOWN',
  YALE = 'YALE',
  NEST = 'NEST',
  BRIVO = 'BRIVO',
  HONEYWELL = 'HONEYWELL',
  IGLOO = 'IGLOO',
}

export enum YaleUserTypeEnum {
  USER = 'USER',
  OWNER = 'OWNER',
  MANAGER = 'MANAGER',
  LIMITED = 'LIMITED',
  SUPERUSER = 'SUPERUSER',
  UNVERIFIED = 'UNVERIFIED',
}
