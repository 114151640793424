import { ApthubAccessScheduleType, DeviceMakerEnum, YaleUserTypeEnum } from '../../enums';
import { PersonTypeCodeEnum, ResidentTypeCodeEnum } from '../../queries/enums';
import { DeviceMaker, TCommonError, YaleUserType } from './../../types';

export enum AccessScheduleTypes {
  ALWAYS = 'ALWAYS',
  TEMPORARY = 'TEMPORARY',
  RECURRING = 'RECURRING',
  UNKNOWN = 'UNKNOWN',
}

export type TYalePinAccess = {
  accessType: ApthubAccessScheduleType;
  accessTimes?: string;
  accessRecurrence?: string;
};

export type TPinAccess = TPermanentPin | TTemporaryPin | TRecurringPin;

export type TPermanentPin = {
  accessType: 'ALWAYS';
};

export type TTemporaryPin = {
  accessType: 'TEMPORARY';
  accessTimes: string;
};

export type TRecurringPin = {
  accessType: 'RECURRING';
  accessTimes: string;
  accessRecurrence: string;
};

export type TGrantFullAccessToLocksVariables = {
  input: {
    userId: string;
    deviceMaker: string;
    personType: string;
    residentType: string;
    lockAccesses: {
      yaleDeviceId: string;
      userType: YaleUserTypeEnum;
    }[];
  };
};

export type TGrantFullAccessToLocksResponse = {
  lock: {
    grantFullAccessToLocks: {
      failed: {
        userType: string;
        yaleDeviceId: string;
      }[];
      granted: {
        userType: string;
        yaleDeviceId: string;
      }[];
    };
  };
};

export type TGrantLimitedAccessToLocksResponse = {
  lock: {
    grantLimitedAccessToLocks: {
      created: {
        accessType: 'TEMPORARY' | 'RECURRING';
        yaleDeviceId: string;
        ruleId: string;
      }[];
      failed: {
        accessType: 'TEMPORARY' | 'RECURRING';
        yaleDeviceId: string;
        reason: string;
      }[];
    };
  };
};

export type TRevokeAccessToLocksResponse = {
  lock: {
    revokeAccessToSelectedLocks: {
      failed: {
        reason: string;
        installedDeviceId: string;
      }[];
      revoked: {
        installedDeviceId: string;
      }[];
    };
  };
};

export type TRevokeAccessToLocksVariables = {
  input: {
    //TODO: remove DeviceMaker
    deviceMaker: DeviceMaker | DeviceMakerEnum;
    personProfileId: number;
    installedDeviceIds: Array<number>;
  };
};

export type TLockLimitedAccess = {
  accessType?: Exclude<AccessScheduleTypes, AccessScheduleTypes.ALWAYS>;
  deviceId: string;
  endTime: string;
  schedule: string;
  startTime: string;
};

export type TGrantLimitedAccessToLocksVariables = {
  input: {
    userId: string;
    deviceMaker: string;
    personType: string;
    residentType: string;
    lockAccessRules: TLockLimitedAccess[];
  };
};

export type TLockPin = TPinAccess & {
  deviceId: string;
  yaleUserType: YaleUserTypeEnum;
};

export type TSetLockPinsVariables = {
  input: {
    deviceMaker: string;
    userId: string;
    locksToSet: TLockPin[];
    personType: PersonTypeCodeEnum;
    residentType: ResidentTypeCodeEnum;
  };
};

export type TSetLockPinsResponse = {
  lock: {
    setLockPins: {
      pin: string;
      submitted: {
        deviceId: string;
        transactionId: string;
        accessType: AccessScheduleTypes;
      }[];
      failed: {
        reason: string;
      }[];
    };
  };
};

export type TCancelAsyncTransactionResponse = {
  utility: {
    cancelUnsuccessfulAsyncTransaction:
      | {
          softDeletedAsyncTransactionId: number;
        }
      | TCommonError;
  };
};

export type TCancelAsyncTransactionVariables = {
  input: {
    asyncTransactionId: number;
  };
};

export type TRevokeCommonAreaAccessResponse = {
  lock: {
    revokeAccessFromCommonAreaLocks: { success: boolean };
  };
};

export type TRevokeCommonAreaAccessVariables = {
  input: {
    deviceMaker: string;
    personProfileId: number;
  };
};

export type TGrantCommonAreaAccessResponse = {
  lock: {
    grantAccessToCommonAreaLocks: {
      userId: string;
      accessCode?: string;
    };
  };
};

export type TGrantCommonAreaAccessVariables = {
  input: {
    deviceMaker: string;
    personId: number;
    propertyId: number;
    effectiveFrom?: string;
    effectiveTo?: string | null;
    enableAppAccess: boolean;
    personType: PersonTypeCodeEnum;
    residentType: ResidentTypeCodeEnum;
  };
};

export type TSetPinsResponse = {
  lock: {
    setPins: {
      pin: string;
      submitted: {
        installedDeviceId: number;
        accessType: ApthubAccessScheduleType;
        transactionId: string;
      }[];
      failed: {
        installedDeviceId: number;
        accessType: ApthubAccessScheduleType;
        reason: string;
      }[];
    };
  };
};

export type TSetPinsVariables = {
  input: {
    deviceMaker: DeviceMakerEnum;
    igloo: {
      locksToSet: {
        accessType: ApthubAccessScheduleType;
        endDate?: string;
        startDate: string;
        installedDeviceId: number;
        personAccessType: 'RESIDENT' | 'GUEST' | 'PIN_CODE_ENTRY';
      }[];
    };
    personId: number;
    personType: PersonTypeCodeEnum;
    pin?: string;
    residentType: ResidentTypeCodeEnum;
  };
};

export type TGrantFullLockAccessResponse = {
  lock: {
    grantFullLockAccess: {
      granted: {
        installedDeviceId: number;
        personAccessId: number;
      }[];
      failed: {
        installedDeviceId: number;
        reason: string;
      }[];
      personProfileId: number;
    };
  };
};

export type TGrantFullLockAccessVariables = {
  input: {
    personId: number;
    personType: PersonTypeCodeEnum;
    deviceMaker: DeviceMakerEnum;
    residentType: ResidentTypeCodeEnum;
    igloo: {
      lockAccesses: {
        installedDeviceId: number;
        startDate: string;
      }[];
    };
  };
};

export type IglooPinAccessData = IglooPermanentPinData | IglooDurationPinData;

export type IglooPermanentPinData = {
  accessType: 'ALWAYS';
  startDate: string;
};
export type IglooDurationPinData = {
  accessType: 'TEMPORARY';
  startDate: string;
  endDate: string;
};
