import { DeviceMakerEnum, YaleUserTypeEnum } from '../../data/graphql/enums';
import { TLockPin } from '../../data/graphql/mutations/lock/types';
import { AccessTypeCodeEnum } from '../../data/graphql/queries/enums';
import YalePinUtils from '../../utils/PinUtils/YalePinUtils';
import Api from '../Api';
import { UnifiedAccessData } from './types';

const YaleAccessService = {
  grantFullAccess: async (data: UnifiedAccessData) => {
    const partnerUserType = data.person.partnerUserType;

    if (!data.person.partnerUserId) {
      throw new Error('Yale user id is required');
    } else if (!partnerUserType) {
      throw new Error('Yale user type is required');
    }

    const response = await Api.lock.grantFullAccessToLocks({
      deviceMaker: DeviceMakerEnum.YALE,
      userId: data.person.partnerUserId,
      personType: data.person.personType,
      residentType: data.person.residentType,
      lockAccesses: data.accesses.map((access) => ({
        userType: partnerUserType,
        yaleDeviceId: access.device.partnerLockId,
      })),
    });

    return response?.failed.map(({ yaleDeviceId }) => yaleDeviceId) || [];
  },

  setPins: async (data: UnifiedAccessData) => {
    const yaleUserType = data.person.partnerUserType;

    if (!data.accesses.length) {
      return Promise.resolve({
        pin: '',
        submitted: [],
        failed: [],
      });
    } else if (!yaleUserType) {
      throw new Error('Yale user type is required');
    } else if (!data.person.partnerUserId) {
      throw new Error('Yale user id is required');
    }

    const locksToSet = data.accesses.map<TLockPin | null>(({ device, access }) => {
      if (access.scheduleType === 'ALWAYS') {
        return {
          yaleUserType,
          deviceId: device.partnerLockId,
          accessType: 'ALWAYS',
        };
      } else if (access.scheduleType === 'TEMPORARY' && access.endDateTime) {
        return {
          yaleUserType,
          deviceId: device.partnerLockId,
          accessType: 'TEMPORARY',
          accessTimes: YalePinUtils.getTemporaryPinAccessTimes(access.startDateTime, access.endDateTime),
        };
      } else if (access.scheduleType === 'RECURRING' && access.days && access.startDateTime && access.endDateTime) {
        const pinData = YalePinUtils.getRecurringPinAccessParams(access.days, access.startDateTime, access.endDateTime);

        return {
          yaleUserType,
          deviceId: device.partnerLockId,
          accessType: 'RECURRING',
          accessTimes: pinData.accessTimes,
          accessRecurrence: pinData.accessRecurrence,
        };
      }

      return null;
    });

    const response = await Api.lock.setLockPins({
      locksToSet: locksToSet.filter((lock) => lock !== null) as TLockPin[],
      userId: data.person.partnerUserId,
      deviceMaker: DeviceMakerEnum.YALE,
      personType: data.person.personType,
      residentType: data.person.residentType,
    });

    return (
      response || {
        pin: '',
        submitted: [],
        failed: [],
      }
    );
  },
};

export default YaleAccessService;
