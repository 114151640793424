import { addHours } from 'date-fns';
import { TAccessScheduleFields } from '../../../components/AccessSchedule/AccessSchedule';
import { AccessScheduleTypes } from '../../../data/graphql/mutations/lock/types';
import { TPersonAccess } from '../../../data/graphql/queries/entities';
import { dateToDayTimeObject } from '../../../functions';

const IglooServiceAccessUtils = {
  getScheduleType: (miscInfo: TPersonAccess['miscInfo']): AccessScheduleTypes => {
    const access = miscInfo?.iglooLockAccess;

    if (access?.appAccess?.accessType) {
      return access.appAccess.accessType as AccessScheduleTypes;
    } else if (access?.pin?.accessType) {
      return access.pin.accessType as AccessScheduleTypes;
    }

    return AccessScheduleTypes.UNKNOWN;
  },

  getServiceAccessData: (
    data: Pick<TPersonAccess, 'miscInfo' | 'personAccessTypeId'>,
  ): TAccessScheduleFields | null => {
    const { miscInfo, personAccessTypeId } = data;

    const access = miscInfo?.iglooLockAccess;

    if (!access) {
      return null;
    }

    if (access.appAccess) {
      const weekDays = [];
      const endDate = new Date(access.appAccess.endDate);
      const startDate = new Date(access.appAccess.startDate);

      return {
        accessType: 'app',
        accessScheduleType: access.appAccess.accessType as AccessScheduleTypes,
        startDate,
        endDate,
        weekDays,
        startTime: dateToDayTimeObject(startDate),
        endTime: dateToDayTimeObject(endDate),
      };
    } else if (access.pin) {
      const weekDays = [];
      const startDate = new Date(access.pin.startDate);
      const endDate = access.pin.endDate ? new Date(access.pin.endDate) : addHours(startDate, 1);

      return {
        accessType: 'pin',
        accessScheduleType: access.pin.accessType as AccessScheduleTypes,
        startDate,
        endDate,
        weekDays,
        startTime: dateToDayTimeObject(startDate),
        endTime: dateToDayTimeObject(endDate),
      };
    }

    return null;
  },
};

export default IglooServiceAccessUtils;
