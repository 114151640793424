import { useMemo } from 'react';
import LocalStorageUtils from '../../functions/localStorage.functions';
import { DeviceTypeCodeEnum } from '../../data/graphql/queries/enums';

const useDeviceTypes = () => {
  const DeviceTypeIds = useMemo<Record<DeviceTypeCodeEnum, number>>(() => {
    const types = LocalStorageUtils.getItem('deviceTypes');

    const getId = (code: DeviceTypeCodeEnum) => {
      return types?.byCode[code]?.id || -1;
    };

    return {
      [DeviceTypeCodeEnum.NEST_E]: getId(DeviceTypeCodeEnum.NEST_E),
      [DeviceTypeCodeEnum.YALE_622]: getId(DeviceTypeCodeEnum.YALE_622),
      [DeviceTypeCodeEnum.YALE_BRIDGE]: getId(DeviceTypeCodeEnum.YALE_BRIDGE),
      [DeviceTypeCodeEnum.YALE_DS]: getId(DeviceTypeCodeEnum.YALE_DS),
      [DeviceTypeCodeEnum.YALE_ASSURE_2]: getId(DeviceTypeCodeEnum.YALE_ASSURE_2),
      [DeviceTypeCodeEnum.BRIVO]: getId(DeviceTypeCodeEnum.BRIVO),
      [DeviceTypeCodeEnum.HONEYWELL_T6]: getId(DeviceTypeCodeEnum.HONEYWELL_T6),
      [DeviceTypeCodeEnum.IGLOO_NB]: getId(DeviceTypeCodeEnum.IGLOO_NB),
    };
  }, []);

  return { DeviceTypeIds };
};

export default useDeviceTypes;
