import { useLazyQuery, useQuery } from '@apollo/client';
import {
  TGetServicePersonAccessesResponse,
  TGetServicePersonAccessesVariables,
} from '../../data/graphql/queries/people/types';
import { GET_SERVICE_PERSON_ACCESSES } from '../../data/graphql/queries/people';
import useToast from '../useToast';
import { useEffect } from 'react';

type ServicePersonAccessesProps = {
  // profileId: number
  personId: number;
};

const useServicePersonAccesses = ({ personId }: ServicePersonAccessesProps) => {
  const { showErrorToast } = useToast();
  const [getPersonAccesses, response] = useLazyQuery<
    TGetServicePersonAccessesResponse,
    TGetServicePersonAccessesVariables
  >(GET_SERVICE_PERSON_ACCESSES, {
    variables: {
      personId: Number(personId),
      accessesCondition: {
        personId: Number(personId),
        isActive: true,
        isDeleted: false,
      },
      profilesCondition: {
        personId: Number(personId),
        isDeleted: false,
      },
    },
    onError() {
      showErrorToast('Request Error', 'Unable to Retrieve Person Accesses Data');
    },
  });

  useEffect(() => {
    if (personId) {
      getPersonAccesses();
    }
  }, [personId, getPersonAccesses]);

  return {
    person: personId ? response?.data?.db?.person : undefined,
    profiles: personId ? response?.data?.db?.profiles.nodes : undefined,
    accesses: personId ? response?.data?.db?.accesses.nodes : undefined,
    response,
  };
};

export default useServicePersonAccesses;
