const safeParse = (value?: string | number | null) => {
  const parsed = Number(value);
  return isNaN(parsed) ? 0 : parsed;
};

const NumberUtils = {
  safeParse,
};

export default NumberUtils;
