import { useCallback, useState } from 'react';

import { client } from '../../../data/graphql';
import { SEND_APP_INVITE } from '../../../data/graphql/queries/utility';
import { TSendAppInviteResponse, TSendAppInviteVariables } from '../../../data/graphql/queries/utility/types';
import { DeviceTypeCodeEnum, ResidentTypeCodeEnum } from '../../../data/graphql/queries/enums';
import useDeviceTypes from '../../../hooks/types/useDeviceTypes';

export interface TPendingUser {
  personName: string;
  unitId: string;
  propertyId: string;
  residentTypeCode: string;
  personType: string;
  email: string;
  mobilePhone: string;
}

type TInstalledDevice = {
  unitId: string;
  deviceTypeId: string;
  installedDeviceId: string;
  deviceLocationTypeId: string;
};

const residentType = {
  [ResidentTypeCodeEnum.HEAD_OF_HOUSEHOLD]: 'HEAD_OF_HOUSEHOLD',
  [ResidentTypeCodeEnum.CO_HEAD_OF_HOUSEHOLD]: 'CO_HEAD_OF_HOUSEHOLD',
  [ResidentTypeCodeEnum.ADULT_RESIDENT]: 'ADULT_RESIDENT',
  [ResidentTypeCodeEnum.CHILD_RESIDENT]: 'CHILD_RESIDENT',
};

const usePendingUserInvite = () => {
  const [loading, setLoading] = useState(false);
  const { DeviceTypeIds } = useDeviceTypes();

  const sendInvitation = useCallback(
    async (
      user: TPendingUser,
      devices: {
        installedLockIds: string[];
        installedThermostatIds: string[];
      },
    ) => {
      setLoading(true);

      try {
        const [firstName, ...lastName] = user.personName.split(/\s/);

        if (!devices.installedLockIds?.length) {
          throw new Error('No installed devices');
        }

        const response = await client.mutate<TSendAppInviteResponse, TSendAppInviteVariables>({
          mutation: SEND_APP_INVITE,
          variables: {
            input: {
              sender: {
                lastName: 'Platform',
                firstName: 'Apthub',
              },
              invitee: {
                email: user.email,
                firstName: firstName,
                lastName: lastName.join(' '),
                mobilePhone: user.mobilePhone,
                personType: 'RESIDENT',
                residentType: residentType[user.residentTypeCode],
              },
              requestedThermostatAccesses: devices.installedThermostatIds.map((installedDeviceId) => ({
                installedDeviceId: +installedDeviceId,
              })),
              requestedCommonAreaLockAccess: { propertyId: Number(user.propertyId) },
              requestedLockAccesses: devices.installedLockIds.map((installedDeviceId) => ({
                installedDeviceId: +installedDeviceId,
              })),
            },
          },
        });

        setLoading(false);
        return response;
      } catch (e) {
        setLoading(false);
        throw e;
      }
    },
    [],
  );

  const invite = useCallback(
    (resident: TPendingUser, installedDevices?: TInstalledDevice[]) => {
      const lockDeviceTypes = [
        DeviceTypeIds[DeviceTypeCodeEnum.YALE_622],
        DeviceTypeIds[DeviceTypeCodeEnum.YALE_ASSURE_2],
        DeviceTypeIds[DeviceTypeCodeEnum.IGLOO_NB],
      ];
      const installedLockIds =
        installedDevices
          ?.filter(({ deviceTypeId }) => lockDeviceTypes.includes(+deviceTypeId))
          .map(({ installedDeviceId }) => installedDeviceId) || [];

      const installedThermostatIds =
        installedDevices
          ?.filter(({ deviceTypeId }) => +deviceTypeId === DeviceTypeIds[DeviceTypeCodeEnum.HONEYWELL_T6])
          .map(({ installedDeviceId }) => installedDeviceId) || [];

      return sendInvitation(resident, { installedLockIds, installedThermostatIds });
    },
    [DeviceTypeIds, sendInvitation],
  );

  return { invite, loading };
};

export default usePendingUserInvite;
