import './ManufacturerRegistration.style.scss';

import Panel from '../../../components/Panel';
import Section from '../../../components/Grid/Section';
import InputSelect from '../../../components/InputSelect';
import { useEffect } from 'react';
import useBuildingAccountSecret from '../useBuildingAccountSecret';
import Spinner from '../../../components/Spinner';
import Credentials from '../Credentials';
import useBuildingSecretForm from '../useBuildingSecretForm';
import { DeviceMaker, SecretType } from '../../../data/graphql/types';
import useToast from '../../../hooks/useToast';

const ManufacturerTypesMap: { [code: string]: DeviceMaker } = {
  B: DeviceMaker.BRIVO,
  Y: DeviceMaker.YALE,
  N: DeviceMaker.NEST,
  H: DeviceMaker.HONEYWELL,
  I: DeviceMaker.IGLOO,
};

const ManufacturerRegistration = () => {
  const { showErrorToast } = useToast();
  const form = useBuildingSecretForm();
  const gmailSecret = useBuildingAccountSecret();
  const manufacturerSecret = useBuildingAccountSecret();

  useEffect(() => {
    if (!form.buildingPerson || !form.manufacturerCode) {
      return;
    }

    gmailSecret.fetch({
      buildingPersonId: Number(form.buildingPerson.person.id),
      secretType: SecretType.BUILDING_ACCOUNT_EMAIL_PASSWORD,
    });
  }, [form.buildingId, form.buildingPerson, gmailSecret.fetch, showErrorToast]);

  useEffect(() => {
    if (!form.buildingPerson || !form.manufacturerCode) {
      return;
    }

    if (!ManufacturerTypesMap[form.manufacturerCode]) {
      showErrorToast('Device manufacturer not found');
      return;
    }

    manufacturerSecret.fetch({
      buildingPersonId: Number(form.buildingPerson.person.id),
      secretType: SecretType.PASSWORD,
      deviceManufacturer: ManufacturerTypesMap[form.manufacturerCode],
    });
  }, [form.buildingId, form.buildingPerson, form.manufacturerCode, manufacturerSecret.fetch, showErrorToast]);

  return (
    <Section className="ManufacturerRegistration">
      <h4 className="black">Manufacturer account crendetials</h4>

      <Panel theme={'white'}>
        <Spinner overlay visible={form.loading || gmailSecret.loading || manufacturerSecret.loading} />

        <InputSelect
          allowNull={true}
          defaultOptionLabel="None"
          label={'Select Property'}
          value={form.propertyId}
          options={form.properties}
          onValueChange={form.setPropertyId}
        />

        <InputSelect
          allowNull={true}
          defaultOptionLabel="None"
          disabled={!form.propertyId}
          label={'Select Building'}
          value={form.buildingId}
          options={form.buildings}
          onValueChange={form.setBuildingId}
        />

        <InputSelect
          allowNull={true}
          defaultOptionLabel="None"
          disabled={!form.buildingId || !gmailSecret.data}
          label={'Select third-party system'}
          value={form.manufacturerCode}
          options={form.manufacturers}
          onValueChange={form.setManufacturerCode}
        />

        {!gmailSecret.data && <h6 className="noGmailAccount">No gmail account created for selected building yet</h6>}

        {gmailSecret.data && form.manufacturerCode ? (
          <Credentials
            key={form.manufacturerCode}
            storedEmail={form.buildingPerson?.person.email}
            storedPhone={form.buildingPerson?.person.mobilePhone}
            storedPassword={manufacturerSecret.data}
            onSubmit={(email, password) => {
              if (form.buildingPerson?.person.email) {
                // add building account
              }

              manufacturerSecret.store({
                deviceManufacturer: ManufacturerTypesMap[form.manufacturerCode],
                personSecret: password,
                secretType: SecretType.PASSWORD,
                buildingPersonId: Number(form.buildingPerson?.person.id),
              });
            }}
          />
        ) : null}
      </Panel>
    </Section>
  );
};

export default ManufacturerRegistration;
