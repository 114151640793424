export class FormattedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'FormattedError';
  }

  static isFormattedError(error: any) {
    return error instanceof FormattedError;
  }
}
