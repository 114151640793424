import { startOfToday } from 'date-fns';
import { ApthubAccessScheduleType } from '../../data/graphql/enums';

const YalePinUtils = {
  tempAccessToDates: (accessTimes: string) => {
    const accessTimeNodes = accessTimes.split(';').reduce<Record<'startDate' | 'endDate', string>>(
      (result, current) => {
        if (current.startsWith('DTSTART')) {
          result.startDate = current.replace(/DTSTART=/, '');
        } else if (current.startsWith('DTEND')) {
          result.endDate = current.replace(/DTEND=/, '');
        }

        return result;
      },
      { startDate: '', endDate: '' },
    );

    return {
      accessType: 'TEMPORARY' as ApthubAccessScheduleType,
      startDateTime: new Date(accessTimeNodes.startDate),
      endDateTime: new Date(accessTimeNodes.endDate),
    };
  },

  recurringParamsToDates: (accessTimes: string, accessRecurrence: string) => {
    const accessTimeNodes = accessTimes.split(';').reduce<Record<'startSec' | 'endSec', number>>(
      (result, current) => {
        if (current.startsWith('STARTSEC')) {
          const startSec = Number(current.replace(/STARTSEC=/, ''));
          result.startSec = isNaN(startSec) ? 0 : startSec;
        } else if (current.startsWith('ENDSEC')) {
          const endSec = Number(current.replace(/ENDSEC=/, ''));
          result.endSec = isNaN(endSec) ? 0 : endSec;
        }

        return result;
      },
      {
        startSec: 0,
        endSec: 0,
      },
    );

    const initialDate = startOfToday();

    const startDateTime = new Date(initialDate.getTime() + accessTimeNodes.startSec * 1000);
    const endDateTime = new Date(initialDate.getTime() + accessTimeNodes.endSec * 1000);

    const byDaysString = accessRecurrence.split(';').find((item) => item.startsWith('BYDAY='));

    const selectedDays = YalePinUtils.daysReccurenceToIndexes(byDaysString);

    return {
      accessType: 'RECURRING' as ApthubAccessScheduleType,
      startDateTime,
      endDateTime,
      days: selectedDays,
    };
  },

  daysReccurenceToIndexes: (daysRecurrence?: string) => {
    const days = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];

    if (!daysRecurrence) {
      return [];
    }

    return daysRecurrence
      .replace(/(BYDAY=)|[\s\t\r\n\f\v]/g, '')
      .split(',')
      .map((day) => days.indexOf(day));
  },

  getTemporaryPinAccessTimes: (startDateTime: Date, endDateTime: Date) => {
    return `DTSTART=${startDateTime.toISOString()};DTEND=${endDateTime.toISOString()}`;
  },

  getRecurringPinAccessParams: (days: number[], startDateTime: Date, endDateTime: Date) => {
    const daysNames = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];

    const selectedDays = days.map((index) => daysNames[index]);
    const endSec = (endDateTime.getHours() * 60 + endDateTime.getMinutes()) * 60;
    const startSec = (startDateTime.getHours() * 60 + startDateTime.getMinutes()) * 60;

    const accessTimes = `STARTSEC=${startSec};ENDSEC=${endSec}`;
    const accessRecurrence = 'FREQ=WEEKLY;INTERVAL=1;BYDAY=' + selectedDays.join(',');

    return {
      accessTimes,
      accessRecurrence,
    };
  },
};

export default YalePinUtils;
