import { useQuery } from '@apollo/client';
import { TUnitDetailsRespone, TUnitDetailsVariables } from '../../data/graphql/queries/properties/types';
import { GET_UNIT_DETAILS } from '../../data/graphql/queries/properties';
import { useParams } from 'react-router-dom';
import useToast from '../../hooks/useToast';
import { useCallback, useMemo } from 'react';
import useAllTypes from '../../hooks/data/useAllTypes';
import { AccessTypeCodeEnum, DeviceTypeCodeEnum, PersonTypeCodeEnum } from '../../data/graphql/queries/enums';
import useDeviceTypes from '../../hooks/types/useDeviceTypes';

const activeCondition = {
  isActive: true,
  isDeleted: false,
};

const useUnitDetails = () => {
  const params = useParams();
  const { showToast } = useToast();
  const types = useAllTypes();
  const { DeviceTypeIds } = useDeviceTypes();
  const unitId = Number(params.unitId);
  const propertyId = Number(params.propertyId);

  const residentAccessTypeId = types.getId('resident', AccessTypeCodeEnum.RESIDENT);

  const unitQuery = useQuery<TUnitDetailsRespone, TUnitDetailsVariables>(GET_UNIT_DETAILS, {
    variables: {
      unitId: unitId,
      propertyId: propertyId,
      leaseCondition: {
        ...activeCondition,
        isExpired: false,
      },
      thermostatsFilter: {
        isActive: {
          equalTo: true,
        },
        isDeleted: {
          equalTo: false,
        },
        deviceTypeId: {
          in: [DeviceTypeIds[DeviceTypeCodeEnum.NEST_E], DeviceTypeIds[DeviceTypeCodeEnum.HONEYWELL_T6]],
        },
      },
      locksFilter: {
        isActive: {
          equalTo: true,
        },
        isDeleted: {
          equalTo: false,
        },
        deviceTypeId: {
          in: [
            DeviceTypeIds[DeviceTypeCodeEnum.YALE_622],
            DeviceTypeIds[DeviceTypeCodeEnum.IGLOO_NB],
            DeviceTypeIds[DeviceTypeCodeEnum.YALE_ASSURE_2],
          ],
        },
      },
      residentsCondition: {
        ...activeCondition,
        unitId: Number(unitId),
      },
      personAccessesCondition: {
        ...activeCondition,
        unitId: Number(unitId),
        personAccessTypeId: residentAccessTypeId,
      },

      guestsFilter: {
        isPersonAccessActive: {
          equalTo: true,
        },
        unitId: {
          equalTo: unitId,
        },
        and: [
          {
            or: [
              {
                personAccessCode: {
                  equalTo: AccessTypeCodeEnum.GUEST,
                },
              },
              {
                personAccessCode: {
                  equalTo: AccessTypeCodeEnum.PIN_CODE,
                },
              },
            ],
          },

          {
            or: [
              {
                personType: {
                  equalTo: PersonTypeCodeEnum.RESIDENT,
                },
              },
              {
                personType: {
                  equalTo: PersonTypeCodeEnum.GUEST,
                },
              },
            ],
          },
        ],
      },
    },
    onError() {
      showToast({
        title: 'Request Error',
        message: 'Unable to Retrieve Unit Data',
        type: 'error',
      });
    },
  });

  const getUnitAddress = useCallback(() => {
    const address = unitQuery.data?.db?.property?.address;

    if (!address) {
      return '';
    }

    return `${address?.address1} - ${address?.city}, ${address?.state?.code} ${address?.zipCode}`;
  }, [unitQuery.data?.db?.property?.address]);

  const data = useMemo(() => {
    const response = unitQuery.data?.db;
    const residentsData: TUnitDetailsRespone['db']['residents']['nodes'] = response?.residents?.nodes || [];
    const guestsData: TUnitDetailsRespone['db']['guests']['nodes'] = response?.guests?.nodes || [];

    const isLeaseActive = (lease: TUnitDetailsRespone['db']['residents']['nodes'][number]['lease']) => {
      if (!lease) return false;

      const { isActive, isDeleted, isExpired, type, moveOutDate } = lease;

      if (type?.code === 'MO') {
        return false;
      } else if (moveOutDate && new Date(moveOutDate) < new Date()) {
        return false;
      }

      return isActive && !isDeleted && !isExpired;
    };

    const residents = residentsData
      .filter(({ lease }) => isLeaseActive(lease))
      .map(({ person, lease }) => ({
        leaseId: lease?.leaseId,
        unitId: unitId.toString(),
        personName: `${person.firstName} ${person.lastName}`,
        profileId: person.accesses.nodes[0]?.personProfileId,
        personAccessId: person.accesses.nodes[0]?.personAccessId,
      }));

    const guests = guestsData.map((person) => ({
      unitId: unitId.toString(),
      profileId: person.personProfileId,
      personName: person.personName,
      personAccessId: person.personAccessId,
    }));

    const unit = response?.unit
      ? {
          number: response.unit.unitNumber,
          address: getUnitAddress(),
        }
      : null;

    return {
      unit,
      guests,
      residents,
      property: response?.property,
      locks: response?.unit?.locks,
      thermostats: response?.unit?.thermostats,
      leaseholder: response?.unit?.leases.nodes[0]?.leaseholder,
    };
  }, [getUnitAddress, unitId, unitQuery.data?.db]);

  return {
    data,
    loading: unitQuery.loading,
  };
};

export default useUnitDetails;
