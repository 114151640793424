import { DeviceMakerEnum } from '../../data/graphql/enums';
import Api from '../Api';
import { UnifiedAccessData } from './types';

const IglooAccessService = {
  grantFullAccess: async (data: UnifiedAccessData) => {
    if (!data.person.personId) {
      throw new Error('Person ID is required');
    }

    const response = await Api.lock.grantFullLockAccess({
      deviceMaker: DeviceMakerEnum.IGLOO,
      personId: data.person.personId,
      personType: data.person.personType,
      residentType: data.person.residentType,
      igloo: {
        lockAccesses: data.accesses.map((access) => ({
          installedDeviceId: access.device.installedDeviceId,
          startDate: access.access.startDateTime.toISOString(),
        })),
      },
    });

    return response?.failed.map(({ installedDeviceId }) => installedDeviceId.toString()) || [];
  },

  setPins: async (data: UnifiedAccessData) => {
    if (!data.accesses.length) {
      return Promise.resolve({
        pin: '',
        submitted: [],
        failed: [],
      });
    } else if (!data.person.personId) {
      throw new Error('Person record is required');
    }

    const response = await Api.lock.setPins({
      deviceMaker: DeviceMakerEnum.IGLOO,
      igloo: {
        locksToSet: data.accesses.map(({ device, access }) => ({
          accessType: access.scheduleType,
          endDate: access.endDateTime?.toISOString(),
          startDate: access.startDateTime.toISOString(),
          installedDeviceId: device.installedDeviceId,
          personAccessType: 'RESIDENT',
        })),
      },
      personId: data.person.personId,
      personType: data.person.personType,
      residentType: data.person.residentType,
    });

    return (
      response || {
        pin: '',
        submitted: [],
        failed: [],
      }
    );
  },
};

export default IglooAccessService;
