import { DeviceMakerEnum } from '../../data/graphql/enums';
import Api from '../Api';
import BrivoAccessService from './BrivoAccessService';
import IglooAccessService from './IglooAccessService';
import { UnifiedAccessData } from './types';
import YaleAccessService from './YaleAccessService';

const AccessService = {
  grantFullAccess: async (data: UnifiedAccessData) => {
    if (!data.accesses.length) {
      return Promise.resolve([]);
    }

    if (data.deviceMaker === DeviceMakerEnum.YALE) {
      return YaleAccessService.grantFullAccess(data);
    } else if (data.deviceMaker === DeviceMakerEnum.BRIVO) {
      return BrivoAccessService.grantFullAccess(data);
    } else if (data.deviceMaker === DeviceMakerEnum.IGLOO) {
      return IglooAccessService.grantFullAccess(data);
    }

    throw new Error('Invalid device maker');
  },

  revokeAccess: async (data: UnifiedAccessData) => {
    if (!data.person.personProfileId) {
      throw new Error('Person profile ID is required');
    }

    if (!data.accesses.length) {
      return Promise.resolve({
        failed: [],
        revoked: [],
      });
    }

    if (data.deviceMaker === DeviceMakerEnum.BRIVO) {
      return BrivoAccessService.revokeAccess(data);
    }

    return Api.lock.revokeAccessToLocks({
      deviceMaker: data.deviceMaker,
      personProfileId: data.person.personProfileId,
      installedDeviceIds: data.accesses.map((access) => access.device.installedDeviceId),
    });
  },

  setPins: async (data: UnifiedAccessData) => {
    if (data.deviceMaker === DeviceMakerEnum.YALE) {
      return YaleAccessService.setPins(data);
    } else if (data.deviceMaker === DeviceMakerEnum.IGLOO) {
      return IglooAccessService.setPins(data);
    }

    throw new Error('Invalid device maker');
  },
};

export default AccessService;
