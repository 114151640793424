import { gql } from '@apollo/client';

export const GRANT_FULL_ACCESS_TO_LOCKS = gql`
  mutation GrantFullAccessToLocks($input: GrantFullAccessToLocksInput!) {
    lock {
      grantFullAccessToLocks(input: $input) {
        ... on CommonError {
          code
          message
          status
          source
        }
        ... on GrantFullAccessToLocksResult {
          failed {
            userType
            yaleDeviceId
            reason
          }
          granted {
            yaleDeviceId
            userType
          }
        }
      }
    }
  }
`;

export const GRANT_LIMITED_ACCESS_TO_LOCKS = gql`
  mutation GrantLimitedAccessToLocks($input: GrantLimitedAccessToLocksInput!) {
    lock {
      grantLimitedAccessToLocks(input: $input) {
        ... on CommonError {
          code
          message
          status
          source
        }
        ... on GrantLimitedAccessToLocksResult {
          created {
            yaleDeviceId
            accessType
            ruleId
          }
          failed {
            yaleDeviceId
            accessType
            reason
          }
        }
      }
    }
  }
`;

export const REVOKE_ACCESSES_TO_LOCKS = gql`
  mutation RevokeAccessToSelectedLocks($input: RevokeAccessToSelectedLocksInput!) {
    lock {
      revokeAccessToSelectedLocks(input: $input) {
        ... on RevokeAccessToLocksResult {
          revoked {
            installedDeviceId
          }
          failed {
            installedDeviceId
            reason
          }
        }
        ... on CommonError {
          code
          message
          source
          status
        }
      }
    }
  }
`;

export const SET_LOCK_PINS = gql`
  mutation Lock($input: SetLockPinsInput!) {
    lock {
      setLockPins(input: $input) {
        ... on CommonError {
          code
          message
          status
          source
        }
        ... on SetLockPinsResult {
          submitted {
            deviceId
            transactionId
            accessType
          }
          failed {
            deviceId
            reason
          }
          pin
        }
      }
    }
  }
`;

export const CANCEL_ASYNC_TRANSACTION = gql`
  mutation Mutation($input: CancelUnsuccessfulAsyncTransactionInput!) {
    utility {
      cancelUnsuccessfulAsyncTransaction(input: $input) {
        ... on CancelUnsuccessfulAsyncTransactionSuccess {
          softDeletedAsyncTransactionId
        }
        ... on CommonError {
          code
          message
          status
          source
        }
      }
    }
  }
`;

export const REVOKE_COMMON_AREA_ACCESSES = gql`
  mutation RevokeAccessFromCommonAreaLocks($input: RevokeAccessFromCommonAreaLocksInput!) {
    lock {
      revokeAccessFromCommonAreaLocks(input: $input) {
        ... on RevokeAccessFromCommonAreaLocksSuccess {
          success
        }
        ... on CommonError {
          code
          message
          status
          source
        }
      }
    }
  }
`;

export const GRANT_COMMON_AREA_ACCESSES = gql`
  mutation GrantAccessToCommonAreaLocks($input: GrantAccessToCommonAreaLocksInput!) {
    lock {
      grantAccessToCommonAreaLocks(input: $input) {
        ... on GrantAccessToCommonAreaLocksSuccess {
          userId
          accessCode
        }
        ... on RetriableError {
          message
          source
        }
        ... on CommonError {
          code
          message
          status
          source
        }
      }
    }
  }
`;

export const SET_PINS = gql`
  mutation SetPins($input: SetPinsInput!) {
    lock {
      setPins(input: $input) {
        ... on SetPinsResult {
          pin
          submitted {
            installedDeviceId
            accessType
            transactionId
            completionTime
          }
          failed {
            installedDeviceId
            accessType
            reason
          }
        }
        ... on CommonError {
          code
          message
          status
          source
        }
      }
    }
  }
`;

export const GRANT_FULL_LOCK_ACCESS = gql`
  mutation GrantFullLockAccess($input: GrantFullLockAccessInput!) {
    lock {
      grantFullLockAccess(input: $input) {
        ... on GrantLockAccessResult {
          granted {
            installedDeviceId
            personAccessId
          }
          failed {
            installedDeviceId
            reason
          }
          personProfileId
        }
        ... on CommonError {
          code
          message
          status
          source
        }
      }
    }
  }
`;
