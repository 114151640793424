/** Checks to see if an array is valid and not empty */
export function arrayIsValid<T>(array: T[]): boolean {
  return Array.isArray(array) && array.length > 0;
}

/** sorts an array by a key value */
export function sortByKey<T>(array: T[], key: string, order: 'asc' | 'desc' = 'asc'): T[] {
  // if the array is empty or not an array do nothing
  if (!arrayIsValid<T>(array)) {
    return array;
  }

  const resArray = [...array];

  // if the key is undefined or a blank string set the order and return
  if (key === undefined || key === '') {
    return order === 'desc' ? resArray.reverse() : resArray;
  }

  // all good so far so lets sort the array by the key
  resArray.sort((a: T, b: T) => {
    if (a[key] < b[key]) {
      return -1;
    } else if (a[key] > b[key]) {
      return 1;
    } else {
      return 0;
    }
  });

  // return sorted array in ascending or descending order
  return order === 'desc' ? resArray.reverse() : resArray;
}

export const arrayToObject = <T extends Record<string, unknown>>(
  array: T[] | undefined,
  key: keyof T | ((item: T) => string),
): Record<string, T> => {
  if (!array) {
    return {};
  }

  return array.reduce<Record<string, T>>((result, current) => {
    const resultKey = typeof key === 'function' ? key(current) : String(current[key]);
    result[resultKey] = current;
    return result;
  }, {});
};

export const findObject = <T = unknown>(array: T[], key: keyof T, value: any) => {
  return array.find((item) => item[key] === value);
};

const ArrayUtils = {
  extractField: <T = unknown>(array: T[], key: keyof T) => {
    if (!array) {
      return [];
    }

    return array?.map((item) => item[key]);
  },
  groupBy: <T = unknown>(array?: T[], key?: keyof T) => {
    if (!array || !key) {
      return {};
    }

    return array.reduce<Record<string, T[]>>((result, current) => {
      const resultKey = String(current[key]);
      result[resultKey] = result[resultKey] || [];
      result[resultKey].push(current);
      return result;
    }, {});
  },
  findObject,
  arrayToObject,
  sortByKey,
};

export default ArrayUtils;
