import './Locks.style.scss';

import { useMemo } from 'react';
import Column from '../../../components/Grid/Column';
import Panel from '../../../components/Panel';
import Row from '../../../components/Grid/Row';
import Section from '../../../components/Grid/Section';
import DataGrid from '../../../components/DataGrid';
import Paginator from '../../../components/Paginator';
import Search from '../../../layouts/People/Search/Search';
import SearchFilterInput from '../../../layouts/People/Search/SearchFilterInput';
import SearchFilters from '../../../layouts/People/Search/SearchFilters';
import SearchSortBy from '../../../layouts/People/Search/SearchSortBy';
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary';
import CrashScreen from '../../ScreenCrash/CrashScreen';
import TableFooter from '../../../components/TableFooter/TableFooter';
import TableNumberOfItems from '../../../components/TabelCountItems/TableNumberOfItems';
import DevicesFilter from '../DevicesFilter';
import useLocksTable from './useLocksTable';
import useRestrictedFeatures from '../../../hooks/useRestrictedFeatures';

const Locks = () => {
  const table = useLocksTable();
  const restrictedFeatures = useRestrictedFeatures();
  const isMasterPinAvailalble = restrictedFeatures.features.masterPin;

  const columns = useMemo(() => {
    const result = [
      { key: 'name', name: 'Lock Name', sortable: true },
      { key: 'location', name: 'Location', sortable: true },
      { key: 'status', name: 'Status', sortable: true },
      { key: 'unit', name: 'Unit', sortable: true },
      { key: 'battery', name: 'Battery', sortable: true },
    ];

    if (isMasterPinAvailalble) {
      result.push({ key: 'masterPin', name: 'Master PIN', sortable: false });
    }

    result.push({ key: 'updatedAt', name: 'Updated', sortable: true });

    return result;
  }, [isMasterPinAvailalble]);

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'Locks'} data-testid={'LocksView'}>
        <Section>
          <Row>
            <Column>
              <>
                <Search>
                  <SearchFilterInput
                    placeholder="Search locks"
                    value={table.queryOptions.searchTerm || ''}
                    onValueChange={table.onTypeSearchField}
                  />
                  <SearchFilters
                    filter={DevicesFilter}
                    initialValue={table.queryOptions.filters}
                    onSubmit={table.onSubmitFilter}
                  />
                  <SearchSortBy
                    value={table.queryOptions.orderBy[0] || ''}
                    options={table.sortOptions}
                    onChange={table.onChangeSortOrder}
                  />
                </Search>
                <Panel theme={'white'}>
                  <DataGrid
                    selectedColumn={table.tableSort.column}
                    selectedColumnChange={table.tableSort.setSortColumn}
                    order={table.tableSort.order}
                    columns={columns}
                    rows={table.tableData}
                    loading={table.loading || restrictedFeatures.loading}
                    emptyTableComponent={table.emptyTableMessage}
                  />
                </Panel>

                <TableFooter itemCount={table.totalCount}>
                  <Paginator
                    itemCount={table.totalCount}
                    perPage={table.queryOptions.limit}
                    currentPage={table.queryOptions.page}
                    onPageChange={table.onChangePage}
                  />
                  <TableNumberOfItems value={table.queryOptions.limit} onValueChange={table.onChangeNumberOfItems} />
                </TableFooter>
              </>
            </Column>
          </Row>
        </Section>
      </div>
    </ErrorBoundary>
  );
};

export default Locks;
