import { DeviceMakerEnum } from '../../data/graphql/enums';
import { AccessTypeCodeEnum } from '../../data/graphql/queries/enums';
import Api from '../Api';
import { UnifiedAccessData } from './types';

const BrivoAccessService = {
  grantFullAccess: async (data: UnifiedAccessData) => {
    const personId = data.person.personId;

    if (!personId) {
      throw new Error('Person ID is required');
    }

    const promises = data.accesses.map(async (access) => {
      return Api.lock.grantCommonAreaAccess({
        personId,
        deviceMaker: DeviceMakerEnum.BRIVO,
        personType: data.person.personType,
        residentType: data.person.residentType,
        propertyId: access.access.propertyId,
        effectiveFrom: access.access.startDateTime.toISOString(),
        effectiveTo: access.access.endDateTime?.toISOString() || null,
        enableAppAccess: access.access.accessType !== AccessTypeCodeEnum.PIN_CODE,
      });
    });

    await Promise.all(promises);

    return [];
  },

  revokeAccess: async (data: UnifiedAccessData) => {
    const personProfileId = data.person.personProfileId;

    if (!personProfileId) {
      throw new Error('Person profile ID is required');
    }

    const promises = data.accesses.map(async () => {
      return Api.lock.revokeCommonAreaAccess({
        personProfileId,
        deviceMaker: DeviceMakerEnum.BRIVO,
      });
    });

    await Promise.all(promises);

    return {
      failed: [],
      revoked: [],
    };
  },
};

export default BrivoAccessService;
