import { useFormik } from 'formik';
import { useEffect, useMemo } from 'react';

import { addHours } from 'date-fns';
import { TAccessScheduleFields, TAccessType } from '../../components/AccessSchedule/AccessSchedule';
import { convertHoursTo24, getUserSchedule } from '../../functions/lock.functions';
import useUnitsDevices from '../../hooks/data/useAccessPointsDevices';
import { areSchedulesEqual, flattenAccessPoints } from '../../functions';
import { AccessScheduleTypes } from '../../data/graphql/mutations/lock/types';
import { isCommonAreaBuilding } from '../../functions/devices.function';
import useServicePersonAccesses from '../../hooks/data/useServicePersonAccesses';
import { DeviceTypeCodeEnum } from '../../data/graphql/queries/enums';
import useDeviceTypes from '../../hooks/types/useDeviceTypes';
import { DeviceMakerEnum } from '../../data/graphql/enums';
import PersonAccessUtils from './ServiceAccessUtils';

export type TServiceCommonAreaAccess = {
  propertyId: string;
  startDate?: string;
  endDate?: string;
  deviceMaker: DeviceMakerEnum;
  accessType: TAccessType;
};

export type TServiceTaskAccess = {
  lockId: string;
  installedDeviceId: number;
  deviceMaker: DeviceMakerEnum;
  accessLevel: TAccessType;
  access?: {
    schedule?: string;
    accessType?: AccessScheduleTypes;
    deviceId?: string;
    endTime?: string;
    startTime?: string;
  };
};

type TUnitId = string;
type TBuildingId = string;
type TPropertyId = string;

type TUnit = TAccessScheduleFields | null;

type TBuilding = {
  units: Record<TUnitId, TUnit | null>;
  buildingName: string;
  data?: TAccessScheduleFields;
};

type TFields = {
  isVendorIdentityCreated: boolean;
  schedule: TAccessScheduleFields;
  accessPoints: Record<
    TPropertyId,
    {
      data?: TAccessScheduleFields;
      buildings: Record<TBuildingId, TBuilding>;
    }
  >;
};

type TSubmitDataResponse = Promise<{
  lockAccessesRequested: TServiceTaskAccess[];
  lockAccessesDeleted: TServiceTaskAccess[];
  commonAreaPropertyAccessRequested: TServiceCommonAreaAccess | null;
  commonAreaPropertyAccessDeleted: string;
}>;

const useScheduleAccessPoints = (data: { personId?: string | number; limited?: boolean }) => {
  const { DeviceTypeIds } = useDeviceTypes();
  const { person, accesses } = useServicePersonAccesses({
    personId: data.personId ? Number(data.personId) : 0,
  });

  const unitsDevices = useUnitsDevices(
    [
      DeviceTypeIds[DeviceTypeCodeEnum.YALE_622],
      DeviceTypeIds[DeviceTypeCodeEnum.BRIVO],
      DeviceTypeIds[DeviceTypeCodeEnum.IGLOO_NB],
      DeviceTypeIds[DeviceTypeCodeEnum.YALE_ASSURE_2],
    ],
    {
      isDeviceInstalled: true,
    },
  );

  const isVendorIdentityCreated = !!person?.isIdentityCreated;

  const remoteSchedule = useMemo(() => {
    if (!accesses) {
      return {};
    }

    return accesses.reduce<TFields['accessPoints']>((result, current) => {
      const { propertyId, buildingId, buildingByBuildingId, unitId, miscInfo, personAccessTypeId } = current;

      const accessData = PersonAccessUtils.getServiceAccessData({ miscInfo, personAccessTypeId });

      if (!accessData) {
        return result;
      }

      if (!result[propertyId]) {
        result[propertyId] = {
          buildings: {},
        };
      }

      if (!result[propertyId].buildings[buildingId]) {
        result[propertyId].buildings[buildingId] = {
          buildingName: buildingByBuildingId.buildingName,
          units: {},
        };
      }

      if (isCommonAreaBuilding(buildingByBuildingId)) {
        result[propertyId].buildings[buildingId]!.data = accessData;
        result[propertyId].buildings[buildingId]!.units[unitId] = null;
      } else {
        result[propertyId].buildings[buildingId]!.units[unitId] = accessData;
      }

      return result;
    }, {});
  }, [accesses]);

  const getUserAccess = (schedule?: TAccessScheduleFields | null) => {
    if (!schedule) {
      return null;
    }

    const startDateTime = schedule.startDate;
    const endDateTime = schedule.endDate;

    if (schedule.accessScheduleType === AccessScheduleTypes.RECURRING) {
      startDateTime.setHours(convertHoursTo24(schedule.startTime));
      startDateTime.setMinutes(schedule.startTime.minutes);
      endDateTime?.setHours(convertHoursTo24(schedule.endTime));
      endDateTime?.setMinutes(schedule.endTime.minutes);
    }

    const yaleSchedule = schedule
      ? getUserSchedule({
          accessType: schedule.accessScheduleType,
          days: schedule.weekDays,
          endDateTime: endDateTime,
          startDateTime: startDateTime,
        })
      : '';

    return {
      accessLevel: schedule.accessType,
      schedule: yaleSchedule,
      accessType: schedule.accessScheduleType,
      endTime: endDateTime?.toISOString(),
      startTime: startDateTime?.toISOString(),
    };
  };

  const { values, errors, touched, setFieldValue, handleChange, handleBlur, ...form } = useFormik<TFields>({
    initialValues: {
      accessPoints: {},
      isVendorIdentityCreated: false,
      schedule: {
        accessType: 'app',

        accessScheduleType: data?.limited ? AccessScheduleTypes.TEMPORARY : AccessScheduleTypes.ALWAYS,
        startDate: new Date(),
        endDate: addHours(new Date(), 1),
        weekDays: [],

        startTime: {
          hours: 9,
          minutes: 0,
          dayTime: 'am',
        },
        endTime: {
          hours: 11,
          minutes: 0,
          dayTime: 'am',
        },
      },
    },
    onSubmit: (
      values,
    ): Promise<{
      lockAccessesRequested: TServiceTaskAccess[];
      lockAccessesDeleted: TServiceTaskAccess[];
      commonAreaPropertyAccessRequested: TServiceCommonAreaAccess | null;
      commonAreaPropertyAccessDeleted: string;
    }> => {
      const lockAccessesRequested: TServiceTaskAccess[] = [];
      const lockAccessesDeleted: TServiceTaskAccess[] = [];
      const commonAreasAccess = getCommonAreaAccesses(values.accessPoints);

      const localAccessPoints = flattenAccessPoints(values.accessPoints);
      const remoteAccessPoints = flattenAccessPoints(remoteSchedule);

      const diff = getUnitsDiff(remoteAccessPoints, localAccessPoints);

      const unitsToTakeAction = Array.from(new Set([...Object.keys(diff.added), ...Object.keys(diff.deleted)]));

      unitsToTakeAction.forEach((unitId) => {
        const unitDevices = Object.values({
          ...unitsDevices.data[unitId][DeviceTypeIds[DeviceTypeCodeEnum.YALE_622]],
          ...unitsDevices.data[unitId][DeviceTypeIds[DeviceTypeCodeEnum.BRIVO]],
          ...unitsDevices.data[unitId][DeviceTypeIds[DeviceTypeCodeEnum.YALE_ASSURE_2]],
          ...unitsDevices.data[unitId][DeviceTypeIds[DeviceTypeCodeEnum.IGLOO_NB]],
        });

        unitDevices.forEach(({ installedDeviceId, lockId, deviceManufacturer }) => {
          if (!lockId || !installedDeviceId) {
            return;
          }

          const deviceMaker = deviceManufacturer.toUpperCase() as DeviceMakerEnum;

          if (diff.deleted[unitId]) {
            lockAccessesDeleted.push(
              createServiceTaskAccess(lockId, installedDeviceId, deviceMaker, diff.deleted[unitId]),
            );
          }

          if (diff.added[unitId]) {
            lockAccessesRequested.push(
              createServiceTaskAccess(lockId, installedDeviceId, deviceMaker, diff.added[unitId]),
            );
          }
        });
      });

      return Promise.resolve({
        lockAccessesRequested,
        lockAccessesDeleted,
        commonAreaPropertyAccessRequested: commonAreasAccess.requested,
        commonAreaPropertyAccessDeleted: commonAreasAccess.deleted,
      });
    },
  });

  const createServiceTaskAccess = (
    lockId: string,
    installedDeviceId: string,
    deviceMaker: DeviceMakerEnum,
    schedule: TAccessScheduleFields,
  ): TServiceTaskAccess => {
    const access = getUserAccess(schedule);

    return {
      lockId,
      deviceMaker,
      installedDeviceId: +installedDeviceId,
      accessLevel: access?.accessLevel || 'pin',
      access: {
        schedule: access?.schedule,
        accessType: access?.accessType,
        deviceId: lockId,
        endTime: access?.endTime,
        startTime: access?.startTime,
      },
    };
  };

  const getCommonAreaAccesses = (
    accessPoints: Record<
      string,
      {
        data?: TAccessScheduleFields | undefined;
        buildings: Record<TBuildingId, TBuilding>;
      }
    >,
  ): { requested: TServiceCommonAreaAccess | null; deleted: string } => {
    const propertyId = Object.keys(accessPoints)[0];

    if (!propertyId) {
      return { requested: null, deleted: '' };
    }

    const property = accessPoints[propertyId];

    const commonAreaBuilding = Object.values(property.buildings).find((building) => isCommonAreaBuilding(building));
    const remoteCommonAreaSchedule = Object.values(remoteSchedule?.[propertyId]?.buildings || {}).find((building) =>
      isCommonAreaBuilding(building),
    )?.data;

    const isCommonAreaBuildingAccessGranted = !!commonAreaBuilding;

    const localCommonAreaSchedule = isCommonAreaBuildingAccessGranted
      ? commonAreaBuilding?.data || property.data
      : null;

    const isScheduleChanged =
      remoteCommonAreaSchedule &&
      localCommonAreaSchedule &&
      !areSchedulesEqual(remoteCommonAreaSchedule, localCommonAreaSchedule);

    const isAccessDeleted = remoteCommonAreaSchedule && !localCommonAreaSchedule;
    const isAccessAdded = localCommonAreaSchedule && !remoteCommonAreaSchedule;

    return {
      deleted: isAccessDeleted || isScheduleChanged ? propertyId : '',
      requested:
        isAccessAdded || isScheduleChanged
          ? {
              propertyId,
              accessType: localCommonAreaSchedule.accessType,
              startDate: localCommonAreaSchedule.startDate?.toISOString(),
              endDate: localCommonAreaSchedule.endDate?.toISOString(),
              deviceMaker: DeviceMakerEnum.BRIVO,
            }
          : null,
    };
  };

  const getUnitsDiff = (
    remoteUnits: Record<TUnitId, TAccessScheduleFields>,
    localUnits: Record<TUnitId, TAccessScheduleFields>,
  ) => {
    const diff: {
      deleted: Record<TUnitId, TAccessScheduleFields>;
      added: Record<TUnitId, TAccessScheduleFields>;
    } = {
      deleted: {},
      added: {},
    };

    const localUnitsIds = Object.keys(localUnits);
    const remoteUnitsIds = Object.keys(remoteUnits);
    const allUnitIds = Array.from(new Set([...localUnitsIds, ...remoteUnitsIds]));

    allUnitIds.forEach((unitId) => {
      if (remoteUnits[unitId] && !localUnits[unitId]) {
        diff.deleted[unitId] = remoteUnits[unitId];
      }

      if (localUnits[unitId] && !remoteUnits[unitId]) {
        diff.added[unitId] = localUnits[unitId];
      }

      if (localUnits[unitId] && remoteUnits[unitId]) {
        const remoteUnitSchedule = remoteUnits[unitId];
        const localUnitSchedule = localUnits[unitId];

        if (
          localUnitSchedule?.accessType === 'pin' &&
          localUnitSchedule?.accessType !== remoteUnitSchedule?.accessType
        ) {
          if (remoteUnitSchedule) {
            diff.deleted[unitId] = remoteUnitSchedule;
          }
          diff.added[unitId] = localUnitSchedule;
        } else if (
          remoteUnitSchedule &&
          localUnitSchedule &&
          !areSchedulesEqual(remoteUnitSchedule, localUnitSchedule)
        ) {
          diff.added[unitId] = localUnitSchedule;
        }
      }
    });

    return diff;
  };

  const submitForm = () => {
    return form.submitForm() as Promise<TSubmitDataResponse>;
  };

  useEffect(() => {
    const copy = structuredClone(remoteSchedule);
    setFieldValue('accessPoints', copy);
    setFieldValue('isVendorIdentityCreated', isVendorIdentityCreated);
  }, [isVendorIdentityCreated, remoteSchedule]);

  return {
    values,
    errors,
    touched,
    handleBlur,
    submitForm,
    handleChange,
    setFieldValue,
  };
};

export default useScheduleAccessPoints;
