import { useCallback, useState } from 'react';
import './StaffOverview.style.scss';
import { useNavigate } from 'react-router-dom';
import { GET_ASYNC_TRANSACTIONS } from '../../data/graphql/queries/people';
import useScheduleAccessPoints, {
  TServiceCommonAreaAccess,
  TServiceTaskAccess,
} from '../../layouts/VendorUserLayout/useScheduleAccessPoints';
import useToast from '../../hooks/useToast';
import { TAccessPointsValue } from '../../layouts/VendorUserLayout/useAccessPoints';
import { TAccessScheduleFields } from '../../components/AccessSchedule/AccessSchedule';
import { AccessTypeCodeEnum } from '../../data/graphql/queries/enums';
import { DeviceMakerEnum } from '../../data/graphql/enums';
import useServicePersonAccesses from '../../hooks/data/useServicePersonAccesses';
import ArrayUtils from '../../functions/array.functions';
import StaffOverviewUtils from './StaffOverviewUtils';
import { UnifiedAccessData } from '../../services/AccessSerivces/types';
import { client } from '../../data/graphql';
import useStaffOverviewData from './useStaffOverviewData';
import NumberUtils from '../../utils/NumberUtils';
import { FormattedError } from '../../functions/error.functions';

export type UnifiedAccessChanges = {
  requested: {
    yale: UnifiedAccessData;
    igloo: UnifiedAccessData;
    brivo: UnifiedAccessData;
  };
  revoked: {
    yale: UnifiedAccessData;
    igloo: UnifiedAccessData;
    brivo: UnifiedAccessData;
  };
};

const useStaffOverview = () => {
  const { showToast, showErrorToast } = useToast();
  const navigate = useNavigate();
  const staffData = useStaffOverviewData();
  const personId = NumberUtils.safeParse(staffData.employee?.personId);
  const serviceAccesses = useServicePersonAccesses({ personId });
  const accessPoints = useScheduleAccessPoints({ personId });

  const yaleUserId = staffData.employee?.person?.miscInfo?.yaleLock?.userId;
  const isIdentityCreated = staffData.employee?.person?.isIdentityCreated;

  const [modalOpen, setModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [accesses, setAccesses] = useState<UnifiedAccessChanges | null>(null);

  const createUnifiedAccessData = (
    deviceMaker: DeviceMakerEnum,
    value?: TServiceTaskAccess[] | TServiceCommonAreaAccess | string | null,
  ): UnifiedAccessData => {
    const result = StaffOverviewUtils.createUnifiedAccessData(
      StaffOverviewUtils.createUnifiedPerson(staffData.profileId, staffData.employee),
      deviceMaker,
      Array.isArray(value) ? value : [],
    );

    if (typeof value === 'string') {
      result.accesses = result.accesses.map((access) => ({
        ...access,
        propertyId: value ? Number(value) : 0,
      }));
    } else if (value && 'deviceMaker' in value && value.deviceMaker === DeviceMakerEnum.BRIVO) {
      result.accesses = result.accesses.map<UnifiedAccessData['accesses'][number]>((access) => ({
        ...access,
        propertyId: value.propertyId,
        accessType: AccessTypeCodeEnum.GUEST,
        endDateTime: value.endDate ? new Date(value.endDate) : null,
        startDateTime: value.startDate ? new Date(value.startDate) : new Date(),
      }));
    }

    return result;
  };

  const unifyAccesses = (values: {
    lockAccessesRequested?: TServiceTaskAccess[];
    lockAccessesDeleted?: TServiceTaskAccess[];
    commonAreaAccessRequested?: TServiceCommonAreaAccess | null;
    commonAreaAccessDeleted?: string;
  }) => {
    const sourceRequestedCommonAreaAccess = values.commonAreaAccessRequested;
    const requestedCommonAreaAccess: TServiceCommonAreaAccess | null = sourceRequestedCommonAreaAccess
      ? {
          ...sourceRequestedCommonAreaAccess,
          endDate: undefined,
        }
      : null;

    const requestedUnitAccesses = ArrayUtils.groupBy(values.lockAccessesRequested, 'deviceMaker');
    const revokedUnitAccesses = ArrayUtils.groupBy(values.lockAccessesDeleted, 'deviceMaker');

    return {
      requested: {
        yale: createUnifiedAccessData(DeviceMakerEnum.YALE, requestedUnitAccesses[DeviceMakerEnum.YALE]),
        igloo: createUnifiedAccessData(DeviceMakerEnum.IGLOO, requestedUnitAccesses[DeviceMakerEnum.IGLOO]),
        brivo: createUnifiedAccessData(DeviceMakerEnum.BRIVO, requestedCommonAreaAccess),
      },
      revoked: {
        yale: createUnifiedAccessData(DeviceMakerEnum.YALE, revokedUnitAccesses[DeviceMakerEnum.YALE]),
        igloo: createUnifiedAccessData(DeviceMakerEnum.IGLOO, revokedUnitAccesses[DeviceMakerEnum.IGLOO]),
        brivo: createUnifiedAccessData(DeviceMakerEnum.BRIVO, values.commonAreaAccessDeleted),
      },
    };
  };

  const handleUserInvite = async (phoneNumber: string, unifiedAccesses: ReturnType<typeof unifyAccesses>) => {
    try {
      if (!staffData.employee?.person || !phoneNumber) {
        throw new Error('Invalid data');
      }

      setIsUpdating(true);

      await StaffOverviewUtils.inviteEmployee(
        {
          firstName: staffData.employee?.person?.firstName || '',
          lastName: staffData.employee?.person?.lastName || '',
          email: staffData.employee?.workEmail || '',
          phone: phoneNumber,
        },
        unifiedAccesses,
      );

      showToast({
        title: 'Employee has been invited',
        message: `${staffData.info?.name} will receive the notification`,
        type: 'info',
      });

      setIsUpdating(false);
    } catch (e) {
      showErrorToast('Invite failure', 'Failed to send user invite');
    }
  };

  const handleGrantAccesses = async (unifiedAccesses: ReturnType<typeof unifyAccesses>) => {
    setIsUpdating(true);

    const failedRevokes = await StaffOverviewUtils.revokeAccesses(unifiedAccesses.revoked);
    const failedGrants = await StaffOverviewUtils.grantAccesses(unifiedAccesses.requested);

    if (failedRevokes.length || failedGrants.length) {
      showErrorToast('Access update', 'Failed to update some of the access');
    } else {
      showToast({
        title: 'Access update',
        message: 'Accesses have been updated',
        type: 'info',
      });
    }

    Promise.allSettled([
      serviceAccesses.response.refetch(),
      client.refetchQueries({
        include: [GET_ASYNC_TRANSACTIONS],
      }),
    ]);

    setIsUpdating(false);
  };

  const handleSubmit = async () => {
    let accessPointsResult: Awaited<ReturnType<typeof accessPoints.submitForm>>;

    try {
      accessPointsResult = await accessPoints.submitForm();
    } catch (e) {
      return showErrorToast('Failed to parse access data', 'Please, refresh the page and try again');
    }

    const hasAccessToChange =
      accessPointsResult.commonAreaPropertyAccessDeleted ||
      accessPointsResult.commonAreaPropertyAccessRequested ||
      accessPointsResult.lockAccessesDeleted.length ||
      accessPointsResult.lockAccessesRequested.length;

    if (!hasAccessToChange) {
      return showErrorToast('Access update', 'No access changes have been detected');
    }

    const unifiedAccesses = unifyAccesses(accessPointsResult);

    if (!staffData.employee?.person?.mobilePhone) {
      // continued in handleModalSubmit with invitation flow
      setAccesses(unifiedAccesses);
      setModalOpen(true);

      return;
    }

    // TODO: check yale user id only in case if yale access granted
    if (!isIdentityCreated || !yaleUserId) {
      handleUserInvite(staffData.employee.person?.mobilePhone, unifiedAccesses);
    } else {
      handleGrantAccesses(unifiedAccesses);
    }
  };

  const handleModalSubmit = async (phone: string) => {
    if (!accesses) {
      return showErrorToast('Access update', 'No access changes have been detected');
    }

    try {
      await handleUserInvite(phone, accesses);
    } catch (e: any) {
      const message = FormattedError.isFormattedError(e) ? e.message : 'Failed to send user invite';

      showErrorToast('Invitation error', message);
    }

    setModalOpen(false);
  };

  const closePhoneModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const openEmployeeActivities = () => {
    navigate(
      encodeURI(`/activity-logs/locks?limit=10&page=1&orderBy=TIME_STAMP_ASC&searchTerm=${staffData.info?.name}`),
    );
  };

  const onChangeAccessPoint = (value: TAccessPointsValue<TAccessScheduleFields>) => {
    accessPoints.setFieldValue('accessPoints', value);
  };

  return {
    loading: staffData.loading,
    profileId: staffData.profileId,

    isUpdating,
    properties: staffData.properties,
    staffInfo: staffData.info,
    employee: staffData.employee,
    accessPoints,

    isInviteModalOpen: modalOpen,

    handleUserInvite,
    closePhoneModal,
    onChangeAccessPoint,

    handleSubmit,
    handleModalSubmit,
    openEmployeeActivities,
  };
};

export default useStaffOverview;
