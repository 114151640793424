// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
import { fromZonedTime } from 'date-fns-tz';
import moment from 'moment-timezone';
import { TAccessScheduleFields } from '../components/AccessSchedule/AccessSchedule';
import { TDayTime } from '../components/TimePicker/TimePicker';
import { AccessScheduleTypes, TPinAccess } from '../data/graphql/mutations/lock/types';
import { format } from 'date-fns';

export const tempDatesToString = (startDateTime: Date, endDateTime: Date) => {
  return `DTSTART=${startDateTime.toISOString()};DTEND=${endDateTime.toISOString()}`;
};

export const weekDaysToIndexes = (days?: string[]) => {
  const daysNames = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];

  if (!days) {
    return [];
  }

  return days.map((dayName) => daysNames.indexOf(dayName));
};

export const indexesToWeekDays = (days?: number[]) => {
  const daysNames = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];

  if (!days) {
    return [];
  }

  return days.map((index) => daysNames[index]);
};

export const convertHoursTo24 = (value: { dayTime: 'am' | 'pm'; hours: number }) => {
  if (value.dayTime === 'pm') {
    if (value.hours !== 12) {
      return value.hours + 12;
    }
  } else if (value.dayTime === 'am' && value.hours === 12) {
    return 0;
  }

  return value.hours;
};

export const recurringDatesToParams = (days: number[], startDateTime: Date, endDateTime?: Date | null) => {
  const selectedDays = indexesToWeekDays(days);
  const endSec = endDateTime ? (endDateTime.getHours() * 60 + endDateTime.getMinutes()) * 60 : 0;
  const startSec = (startDateTime.getHours() * 60 + startDateTime.getMinutes()) * 60;

  const accessTimes = `STARTSEC=${startSec};` + (endSec ? `ENDSEC=${endSec}` : '');
  const accessRecurrence = 'FREQ=WEEKLY;INTERVAL=1;BYDAY=' + selectedDays.join(',');

  return {
    accessTimes,
    accessRecurrence,
  };
};

export const getAccessObject = (pin: {
  accessType?: string;
  accessTimes?: string;
  accessRecurrence?: string;
}): { days?: number[]; endDateTime?: Date; startDateTime?: Date } => {
  if (pin?.accessType === 'RECURRING' && pin.accessTimes && pin.accessRecurrence) {
    return recurringParamsToObject(pin.accessTimes, pin.accessRecurrence);
  } else if (pin?.accessType === 'TEMPORARY' && pin.accessTimes) {
    return tempAccessToDates(pin.accessTimes);
  } else {
    return {};
  }
};

export const tempAccessToDates = (accessTimes: string) => {
  const accessTimeNodes = accessTimes.split(';').reduce<{ startDate: string; endDate: string }>(
    (result, current) => {
      if (current.startsWith('DTSTART')) {
        result.startDate = current.replace(/DTSTART=/, '');
      } else if (current.startsWith('DTEND')) {
        result.endDate = current.replace(/DTEND=/, '');
      }

      return result;
    },
    { startDate: '', endDate: '' },
  );

  return {
    startDateTime: new Date(accessTimeNodes.startDate),
    endDateTime: new Date(accessTimeNodes.endDate),
  };
};

export const recurringParamsToObject = (accessTimes: string, accessRecurrence: string) => {
  const accessTimeNodes = accessTimes.split(';').reduce<{ startSec: number; endSec: number }>(
    (result, current) => {
      if (current.startsWith('STARTSEC')) {
        result.startSec = Number(current.replace(/STARTSEC=/, ''));
      } else if (current.startsWith('ENDSEC')) {
        result.endSec = current.replace(/ENDSEC=/, '') ? +current.replace(/ENDSEC=/, '') : 0;
      }

      return result;
    },
    { startSec: 0, endSec: 0 },
  );

  const initialDate = new Date();
  initialDate.setUTCHours(0);
  initialDate.setUTCMinutes(0);
  initialDate.setUTCSeconds(0);

  const startDateTime = new Date(initialDate.getTime() + accessTimeNodes.startSec * 1000);
  const endDateTime = new Date(initialDate.getTime() + accessTimeNodes.endSec * 1000);

  const byDaysString = accessRecurrence.split(';').find((item) => item.startsWith('BYDAY='));

  const selectedDays = daysReccurenceToIndexes(byDaysString);

  return {
    startDateTime,
    endDateTime,
    days: selectedDays,
  };
};

export const getUserSchedule = (pin?: {
  startDateTime?: Date | string;
  endDateTime?: Date | string | null;
  days: number[];
  accessType: AccessScheduleTypes;
}) => {
  if (!pin || (pin.accessType !== AccessScheduleTypes.RECURRING && pin.accessType !== AccessScheduleTypes.TEMPORARY)) {
    return '';
  }

  const isRecurring = pin.accessType === AccessScheduleTypes.RECURRING;
  const lockTimeZone = moment.tz.guess();

  const startDateTime = moment(pin.startDateTime);
  const endDateTime = pin.endDateTime ? moment(pin.endDateTime) : null;
  const format = 'YYYYMMDD[T]HHmmss';

  if (isRecurring && endDateTime) {
    endDateTime.set('year', startDateTime.get('year'));
    endDateTime.set('month', startDateTime.get('month'));
    endDateTime.set('date', startDateTime.get('date'));
  }

  const DTSTAMP = moment().tz(lockTimeZone).format(format);
  const DTSTART = `TZID=${lockTimeZone}:${startDateTime.tz(lockTimeZone).format(format)}`;

  const DTEND = endDateTime ? `TZID=${lockTimeZone}:${moment(endDateTime).tz(lockTimeZone).format(format)}` : '';

  let schedule = 'BEGIN:VCALENDAR\r\nBEGIN:VEVENT\r\n';
  schedule += `DTSTAMP:${DTSTAMP}\r\n`;
  schedule += `DTSTART;${DTSTART}\r\n`;
  schedule += `DTEND;${DTEND}\r\n`;

  if (pin.accessType === AccessScheduleTypes.RECURRING && pin.days?.length) {
    const { accessRecurrence: RRULE } = recurringDatesToParams(pin.days, startDateTime.toDate(), endDateTime?.toDate());

    schedule += `RRULE:${RRULE}\r\n`;
  }

  schedule += 'END:VEVENT\r\nEND:VCALENDAR';

  return schedule;
};

export const parseUserSchedule = (schedule = '') => {
  const findNode = (nodes: string[] | undefined, startsWith: string) => {
    return nodes?.find((node: string) => node.startsWith(startsWith));
  };

  const dtstampToUtcDate = (dtstamp?: string) => {
    if (!dtstamp) {
      return new Date();
    }

    const [timezone, timestamp] = dtstamp.replace(/^.*?TZID=/, '').split(':');

    return fromZonedTime(timestamp, timezone);
  };

  const scheduleNodes = schedule.replace('\r\n', '\n').split('\n');
  const DTSTART = dtstampToUtcDate(findNode(scheduleNodes, 'DTSTART'));
  const DTEND = dtstampToUtcDate(findNode(scheduleNodes, 'DTEND'));
  const RRULE = findNode(scheduleNodes, 'RRULE');
  const BYDAY = findNode(RRULE?.split(';'), 'BYDAY');
  const days = daysReccurenceToIndexes(BYDAY);

  return {
    startDateTime: DTSTART,
    endDateTime: DTEND,
    days: days,
    accessType: days.length ? AccessScheduleTypes.RECURRING : AccessScheduleTypes.TEMPORARY,
  };
};

export const daysReccurenceToIndexes = (daysRecurrence?: string) => {
  const days = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];

  if (!daysRecurrence) {
    return [];
  }

  return daysRecurrence
    .replace(/(BYDAY=)|[\s\t\r\n\f\v]/g, '')
    .split(',')
    .map((day) => days.indexOf(day));
};

export const getBatteryStatus = (level?: number) => {
  if (typeof level === 'number') {
    if (level < 0.25) {
      return 'empty';
    } else if (level <= 0.5) {
      return 'low';
    } else if (level <= 0.75) {
      return 'partial';
    } else if (level <= 1) {
      return 'full';
    }
  }

  return 'low';
};

export const getInventoryId = (
  unitId: string | number,
  deviceTypeId: string | number,
  locationTypeId: string | number,
) => {
  return `${unitId}:${deviceTypeId}:${locationTypeId}`;
};

export const scheduleToAccessParams = (schedule?: string): TPinAccess => {
  if (schedule) {
    const scheduleData = parseUserSchedule(schedule);

    if (scheduleData.accessType === AccessScheduleTypes.RECURRING) {
      const { accessTimes, accessRecurrence } = recurringDatesToParams(
        scheduleData.days,
        scheduleData.startDateTime,
        scheduleData.endDateTime,
      );

      return {
        accessType: 'RECURRING',
        accessTimes,
        accessRecurrence,
      };
    } else if (scheduleData.accessType === AccessScheduleTypes.TEMPORARY) {
      return {
        accessType: 'TEMPORARY',
        accessTimes: tempDatesToString(scheduleData.startDateTime, scheduleData.endDateTime),
      };
    }
  }

  return {
    accessType: 'ALWAYS',
  };
};

export const pinToString = (pin: { accessType?: string; accessTimes: string; accessRecurrence: string }) => {
  if (!pin) {
    return '';
  } else if (pin.accessType === AccessScheduleTypes.ALWAYS) {
    return 'Always';
  }

  const schedule = getAccessObject(pin);

  if (!schedule.startDateTime || !schedule.endDateTime) {
    return 'Invalid';
  }

  if (pin.accessType === AccessScheduleTypes.RECURRING) {
    const { startDateTime, endDateTime } = schedule;
    const days = indexesToWeekDays(schedule.days).join(', ');

    return `${format(startDateTime, 'HH:mm aaa')} - ${format(endDateTime, 'HH:mm aaa')}, ${days}`;
  } else if (pin.accessType === AccessScheduleTypes.TEMPORARY) {
    return `${format(schedule.startDateTime, 'dd/LL/yyyy HH:mm')} - ${format(
      schedule.endDateTime,
      'dd/LL/yyyy HH:mm',
    )}`;
  }

  return 'Unknown';
};
