import './StaffOverview.style.scss';

import { toCommonDateFormat } from '../../functions';
import Button from '../../components/Button';
import Section from '../../components/Grid/Section';
import Panel from '../../components/Panel';
import DataGrid from '../../components/DataGrid';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import CrashScreen from '../ScreenCrash/CrashScreen';
import Spinner from '../../components/Spinner/Spinner';
import AccessPoints from '../../layouts/VendorUserLayout/ServiceAccessPoints/ServiceAccessPoints';
import ProfileHeader from '../../components/ProfileHeader';
import useStaffOverview from './useStaffOverview';
import PhoneNumberModal from './components/PhoneNumberModal';
import StaffOverviewBreadcrumbs from './components/StaffOverviewBreadcrumbs';

const StaffOverview = () => {
  const {
    loading,
    profileId,

    staffInfo,
    properties,
    accessPoints,
    isUpdating,

    isInviteModalOpen,

    handleSubmit,
    closePhoneModal,
    handleModalSubmit,
    openEmployeeActivities,
    onChangeAccessPoint,
  } = useStaffOverview();

  if (loading) {
    return <Spinner />;
  }

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'StaffOverview'} data-testid={'StaffOverview'}>
        <PhoneNumberModal
          isLoading={isUpdating}
          isOpen={isInviteModalOpen}
          onSubmit={handleModalSubmit}
          onCloseRequest={closePhoneModal}
        />

        <Section>
          <StaffOverviewBreadcrumbs />
        </Section>

        <ProfileHeader spacing={'section-md'} wrapperId={'contact-card'}>
          <ProfileHeader.PersonCard
            name={staffInfo?.name}
            subtitle={staffInfo?.position}
            badgeTheme={staffInfo?.isActive ? 'info' : 'default'}
            badgeLabel={staffInfo?.isActive ? 'Active' : 'Inactive'}
          />

          <ProfileHeader.InfoCard title={'Hire Date'} subtitle={toCommonDateFormat(staffInfo?.hireDate)} />
          <ProfileHeader.InfoCard title={'Email'} subtitle={staffInfo?.email} />
          <ProfileHeader.ButtonCard label="Activity" onClick={openEmployeeActivities} className="activity-container" />
        </ProfileHeader>

        <Section spacing={'none'} id="asigned-properties">
          <div className="d-flex space-between align-center">
            <h4>Assigned Properties</h4>
          </div>

          <Panel theme={'white'}>
            <DataGrid
              id="staff-assigned-properties"
              loading={false}
              columns={[
                { key: 'name', name: 'Property Name' },
                { key: 'access', name: 'Access' },
                { key: 'city', name: 'City' },
                { key: 'state', name: 'State' },
                { key: 'zip', name: 'Zip Code' },
                { key: 'residents', name: 'Residents' },
                { key: 'staff', name: 'Staff' },
              ]}
              rows={properties}
            />
          </Panel>
        </Section>

        <Section id="access-points-section">
          <AccessPoints
            noSchedule
            isVendorIdentityCreated={true}
            personId={Number(staffInfo?.personId)}
            profileId={profileId ? Number(profileId) : 0}
            defaultSchedule={accessPoints.values.schedule}
            value={accessPoints.values.accessPoints}
            onChange={onChangeAccessPoint}
          />

          <Spinner visible={isUpdating} overlay={true} />
        </Section>

        <Section id="actions">
          <Button onClick={handleSubmit}>Update user accesses</Button>
        </Section>
      </div>
    </ErrorBoundary>
  );
};

export default StaffOverview;
