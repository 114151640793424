import { isToday, isYesterday, format } from 'date-fns';
import { TAccessScheduleFields } from '../components/AccessSchedule/AccessSchedule';
import { AccessScheduleTypes } from '../data/graphql/mutations/lock/types';
import { indexesToWeekDays } from './lock.functions';
import { TDayTime } from '../components/TimePicker/TimePicker';

/** Check to see if a date is in the past */
export function isExpired(date): boolean | null {
  if (!date) return null;

  return new Date(date) < new Date();
}

export const formatRelativeTime = (value: number | Date): string => {
  const date = new Date(value);

  if (isToday(date)) {
    return `Today, ${format(date, 'h:mm a')}`;
  } else if (isYesterday(date)) {
    return `Yesterday, ${format(date, 'h:mm a')}`;
  } else {
    return format(date, 'LL/dd/yyyy, h:mm a');
  }
};

export const formatDateTime = (value: number | Date): string => {
  const date = new Date(value);

  return format(date, 'LL/dd/yyyy, h:mm a');
};

/** pretty date formatting */
export function toPrettyDate(date): string | null {
  if (!date) return null;

  if (!(date instanceof Date || typeof date === 'number')) {
    try {
      date = new Date(date);
    } catch (error) {
      console.warn('PrettyDate Pipe can not format this date');
      console.warn(error);
      return date;
    }
  }

  const _value = Number(date);

  const dif = Math.floor((Date.now() - _value) / 1000 / 86400);

  if (dif < 31) {
    return convertToNiceDate(date);
  } else {
    return new Date(date).toLocaleDateString();
  }
}

export function convertToNiceDate(time: string | number): string {
  const date = new Date(time);
  const diff = (new Date().getTime() - date.getTime()) / 1000;
  const daydiff = Math.floor(diff / 86400);

  if (isNaN(daydiff) || daydiff < 0 || daydiff >= 31) {
    return '';
  }

  return ((daydiff === 0 &&
    ((diff < 60 && 'Just now') ||
      (diff < 120 && '1 minute ago') ||
      (diff < 3600 && Math.floor(diff / 60) + ' minutes ago') ||
      (diff < 7200 && '1 hour ago') ||
      (diff < 86400 && Math.floor(diff / 3600) + ' hours ago'))) ||
    (daydiff === 1 && 'Yesterday') ||
    (daydiff < 7 && daydiff + ' days ago') ||
    (daydiff < 31 && Math.ceil(daydiff / 7) + ' week(s) ago')) as string;
}

export function millisecondsToFormat(milliseconds: number, longFormat = false, veryShortFormat = false): string {
  if (milliseconds < 0) {
    return '0';
  }

  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (veryShortFormat) {
    return days > 0 ? `${days}d` : hours > 0 ? `${hours}h` : minutes > 0 ? `${minutes}m` : `${seconds}s`;
  } else if (longFormat) {
    return days > 0
      ? `${days} Day${days > 1 ? 's' : ''}`
      : hours > 0
      ? `${hours} Hour${hours > 1 ? 's' : ''}`
      : `${minutes} Minute${minutes > 1 ? 's' : ''}`;
  } else {
    return days > 0
      ? `${days} Day${days > 1 ? 's' : ''}`
      : hours > 0
      ? `${hours} Hr${hours > 1 ? 's' : ''}`
      : `${minutes} Min${minutes > 1 ? 's' : ''}`;
  }
}

export function toCommonDateFormat(value?: Date | string | number | null): string {
  if (!value) return '';

  const date = typeof value === 'string' ? new Date(value) : value;

  return format(date, 'LL/dd/yyyy');
}

export function toCommonDateTimeFormat(value?: Date | string | number | null): string {
  if (!value) return '';

  const date = typeof value === 'string' ? new Date(value) : value;

  return format(date, 'LL/dd/yyyy hh:mm aa');
}

export const dateToDayTimeObject = (
  date: Date,
): {
  hours: number;
  minutes: number;
  dayTime: 'am' | 'pm';
} => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const dayTime = hours < 12 ? 'am' : 'pm';

  const formattedHours = hours > 12 ? hours - 12 : hours;

  return {
    hours: formattedHours,
    minutes: minutes,
    dayTime: dayTime,
  };
};

export const areSchedulesEqual = (a: TAccessScheduleFields, b: TAccessScheduleFields) => {
  if (a.accessScheduleType !== b.accessScheduleType) {
    return false;
  } else if (a.accessType !== b.accessType) {
    return false;
  }

  if (a.accessScheduleType === AccessScheduleTypes.RECURRING) {
    const areDaysChanged = a.weekDays.some((day) => !b.weekDays.includes(day));

    if (areDaysChanged) {
      return false;
    } else if (
      a.startTime.dayTime !== b.startTime.dayTime ||
      a.startTime.hours !== b.startTime.hours ||
      a.startTime.minutes !== b.startTime.minutes
    ) {
      return false;
    } else if (
      a.endTime.dayTime !== b.endTime.dayTime ||
      a.endTime.hours !== b.endTime.hours ||
      a.endTime.minutes !== b.endTime.minutes
    ) {
      return false;
    }
  }

  if (a.accessScheduleType === AccessScheduleTypes.TEMPORARY) {
    if (a.startDate.toISOString() !== b.startDate?.toISOString()) {
      return false;
    } else if (a.endDate?.toISOString() !== b.endDate?.toISOString()) {
      return false;
    }
  }

  return true;
};

export function isDateInRange(date: Date | string, startDate?: Date | string, endDate?: Date | string): boolean {
  if (!startDate || !endDate) {
    return true;
  }

  const formatedDate = new Date(date);
  const formatedStartDate = new Date(startDate);
  const formatedEndDate = new Date(endDate);
  return formatedDate >= formatedStartDate && formatedDate <= formatedEndDate;
}

export function celsiusToFahrenheit(celsius: number): number {
  return (celsius * 9) / 5 + 32;
}

export const getMondayBasedDayIndex = (date: Date) => {
  const dayIndex = date.getDay();

  if (dayIndex === 0) {
    return 6;
  }

  return dayIndex - 1;
};

export const scheduleToString = (schedule?: TAccessScheduleFields | null) => {
  if (!schedule) {
    return '';
  }

  if (schedule.accessScheduleType === AccessScheduleTypes.RECURRING) {
    const { startTime, endTime, weekDays } = schedule;
    const days = indexesToWeekDays(weekDays).join(', ');

    return `${startTime.hours}:${startTime.minutes} ${startTime.dayTime} - ${endTime.hours}:${endTime.minutes} ${endTime.dayTime}, ${days}`;
  } else if (schedule.accessScheduleType === AccessScheduleTypes.TEMPORARY) {
    if (!schedule.startDate || !schedule.endDate) {
      return '';
    }

    return `${format(schedule.startDate, 'dd/LL/yyyy HH:mm')} - ${format(schedule.endDate, 'dd/LL/yyyy HH:mm')}`;
  }

  return 'Always';
};

export const dateToDaytime = (date?: Date | string) => {
  const time: TAccessScheduleFields['startTime'] = {
    hours: 9,
    minutes: 0,
    dayTime: 'am',
  };

  if (!date) {
    return time;
  }

  const formattedTime = format(new Date(date), 'h:m:aaa');
  const [hours, minutes, dayTime] = formattedTime.split(':');

  time.hours = +hours;
  time.minutes = +minutes;
  time.dayTime = dayTime as TDayTime;

  return time;
};
