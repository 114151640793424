import { AccessScheduleTypes, TYalePinAccess } from '../../../data/graphql/mutations/lock/types';
import { TPersonAccess } from '../../../data/graphql/queries/entities';
import { YaleUserTypeEnum } from '../../../data/graphql/enums';
import { TAccessScheduleFields } from '../../../components/AccessSchedule/AccessSchedule';
import { dateToDayTimeObject } from '../../../functions';
import { addHours } from 'date-fns';
import YalePinUtils from '../../../utils/PinUtils/YalePinUtils';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
import { fromZonedTime } from 'date-fns-tz';

const YaleServiceAccessUtils = {
  getScheduleType: (miscInfo: TPersonAccess['miscInfo']): AccessScheduleTypes => {
    const access = miscInfo?.lockAccess;

    if (access?.yaleUserType === YaleUserTypeEnum.MANAGER || access?.yaleUserType === YaleUserTypeEnum.USER) {
      return AccessScheduleTypes.ALWAYS;
    } else if (access?.limitingRule?.accessType) {
      return access.limitingRule.accessType as AccessScheduleTypes;
    } else if (access?.pin?.accessType) {
      return access.pin.accessType as AccessScheduleTypes;
    }

    return AccessScheduleTypes.UNKNOWN;
  },

  parseUserPin: (pin?: TYalePinAccess | null) => {
    if (pin?.accessType === 'ALWAYS') {
      return {
        accessType: 'ALWAYS',
      };
    } else if (pin?.accessType === 'TEMPORARY' && pin?.accessTimes) {
      return {
        accessType: 'TEMPORARY',
        accessTimes: YalePinUtils.tempAccessToDates(pin.accessTimes),
      };
    } else if (pin?.accessType === 'RECURRING' && pin?.accessTimes && pin?.accessRecurrence) {
      const { startDateTime, endDateTime, days } = YalePinUtils.recurringParamsToDates(
        pin.accessTimes,
        pin.accessRecurrence,
      );

      return {
        accessType: 'RECURRING',
        days,
        endDateTime,
        startDateTime,
      };
    }

    return null;
  },

  parseUserSchedule: (schedule?: string) => {
    if (!schedule) {
      return null;
    }

    const findNode = (nodes: string[], startsWith: string) => {
      return nodes.find((node) => node.startsWith(startsWith)) || '';
    };

    const dtstampToDate = (dtstamp: string): Date | null => {
      const [timezone, timestamp] = dtstamp.replace(/^.*?TZID=/, '').split(':');

      if (!timezone || !timestamp) {
        return null;
      }

      const date = new Date(
        Number(timestamp.substring(0, 4)),
        Number(timestamp.substring(4, 6)) - 1,
        Number(timestamp.substring(6, 8)),
        Number(timestamp.substring(9, 11)),
        Number(timestamp.substring(11, 13)),
        Number(timestamp.substring(13, 15)),
      );

      return fromZonedTime(date, timezone);
    };

    const scheduleNodes = schedule.replace('\r\n', '\n').split('\n');
    const startDateTime = dtstampToDate(findNode(scheduleNodes, 'DTSTART'));
    const endDateTime = dtstampToDate(findNode(scheduleNodes, 'DTEND'));
    const RRULE = findNode(scheduleNodes, 'RRULE');
    const BYDAY = findNode(RRULE?.split(';'), 'BYDAY');
    const days = YalePinUtils.daysReccurenceToIndexes(BYDAY);

    if (!startDateTime || !endDateTime) {
      return null;
    }

    return {
      startDateTime,
      endDateTime,
      days,
      accessType: days.length ? 'RECURRING' : 'TEMPORARY',
    };
  },

  getServiceAccessData: (
    data: Pick<TPersonAccess, 'miscInfo' | 'personAccessTypeId'>,
  ): TAccessScheduleFields | null => {
    const { miscInfo } = data;

    const access = miscInfo?.lockAccess;

    if (!access) {
      return null;
    }

    if (access.yaleUserType !== YaleUserTypeEnum.UNVERIFIED) {
      const scheduleData = YaleServiceAccessUtils.parseUserSchedule(access.limitingRule?.schedule);
      const scheduleType = scheduleData?.accessType || 'ALWAYS';

      return {
        accessType: 'app',
        accessScheduleType: scheduleType as AccessScheduleTypes,
        startDate: scheduleData?.startDateTime || new Date(),
        endDate: scheduleData?.endDateTime || addHours(new Date(), 1),
        weekDays: scheduleData?.days || [],
        startTime: dateToDayTimeObject(scheduleData?.startDateTime || new Date()),
        endTime: dateToDayTimeObject(scheduleData?.endDateTime || addHours(new Date(), 1)),
      };
    } else if (access?.pin) {
      const pinData = YaleServiceAccessUtils.parseUserPin(access.pin as TYalePinAccess);
      const startDate = pinData?.startDateTime || new Date();
      const endDate = pinData?.endDateTime || addHours(new Date(startDate), 1);

      if (pinData) {
        return {
          accessType: 'pin',
          accessScheduleType: pinData.accessType as AccessScheduleTypes,
          weekDays: pinData.days || [],
          startDate,
          endDate,
          startTime: dateToDayTimeObject(startDate),
          endTime: dateToDayTimeObject(endDate),
        };
      }
    }

    return null;
  },
};

export default YaleServiceAccessUtils;
