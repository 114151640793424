import { useEffect, useMemo, useState } from 'react';
import './StaffOverview.style.scss';
import { capitalize } from '../../functions';
import Badge from '../../components/Badge';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_EMPLOYEE } from '../../data/graphql/queries/people';
import { TGetEmployeeResponse, TGetEmployeeVariables } from '../../data/graphql/queries/people/types';
import { PersonTypeCodeEnum } from '../../data/graphql/queries/enums';
import usePersonTypes from '../../hooks/types/usePersonTypes';
import useCurrentProfile from '../../hooks/useCurrentProfile';

export interface IAsignedProperty {
  name: string;
  access: JSX.Element;
  manager: string;
  city: string;
  state: string;
  zip: string;
  residents: number;
  staff: number;
}

export interface IStaffInfo {
  personId: string;
  name: string;
  position: string;
  isActive: boolean;
  email: string;
  hireDate: string;
}

const useStaffOverviewData = () => {
  const { staffId } = useParams();
  const { PersonTypeIds } = usePersonTypes();
  const currentProfile = useCurrentProfile();

  const [staffInfo, setStaffInfo] = useState<IStaffInfo | null>(null);

  const response = useQuery<TGetEmployeeResponse, TGetEmployeeVariables>(GET_EMPLOYEE, {
    skip: !staffId,
    variables: {
      personEmployeeId: staffId ? +staffId : 0,
      managersCondition: {
        isActive: true,
        isDeleted: false,
        departmentCode: 'MGR',
      },
      residentsCondition: {
        isActive: true,
        isDeleted: false,
        personAccessTypeId: 2,
      },
      profilesCondition: {
        isDeleted: false,
      },
    },
  });

  const employee = response.data?.transactionalDb?.employee;

  const employeeProfile = useMemo(() => {
    const profiles = employee?.person?.profiles.nodes;

    if (!profiles) {
      return '';
    }

    const devAdminProfile = profiles.find(
      ({ personTypeId }) => +personTypeId === PersonTypeIds[PersonTypeCodeEnum.DEV_ADMIN],
    );

    if (currentProfile.isDevAdmin && devAdminProfile) {
      return devAdminProfile.personProfileId;
    }

    const employeeProfile = profiles.find(
      ({ personTypeId }) => +personTypeId === PersonTypeIds[PersonTypeCodeEnum.EMPLOYEE],
    );

    if (employeeProfile) {
      return employeeProfile.personProfileId;
    }

    return '';
  }, [PersonTypeIds, employee?.person?.profiles.nodes, currentProfile.isDevAdmin]);

  useEffect(() => {
    if (!employee?.person) {
      return;
    }

    setStaffInfo({
      personId: employee.personId || '0',
      name: capitalize(`${employee.person.firstName} ${employee.person.lastName}`),
      position: employee.departmentDescription || '',
      isActive: employee.isActive,
      hireDate: employee.hireDate || '',
      email: employee.workEmail || '',
    });
  }, [employee]);

  const properties = useMemo<IAsignedProperty[]>(() => {
    const property = employee?.property;
    const manager = property?.manager.nodes[0]?.person;

    if (!property) {
      return [];
    }

    return [
      {
        name: property.propertyName,
        access: (
          <Badge theme={employee.isActive ? 'info' : 'default'} size={'sm'}>
            {employee.isActive ? 'Active' : 'Inactive'}
          </Badge>
        ),
        manager: manager ? capitalize(`${manager.firstName} ${manager.lastName}`) : '—',
        city: property.address.city,
        state: property.address.state.code,
        zip: property.address.zipCode,
        residents: property.residents.totalCount,
        staff: property.staff.totalCount,
      },
    ];
  }, [employee?.property, employee?.isActive]);

  return {
    loading: response.loading,
    profileId: employeeProfile,

    employee,
    info: staffInfo,
    properties,
  };
};

export default useStaffOverviewData;
