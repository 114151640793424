import { addDays } from 'date-fns';
import { TAccessScheduleFields } from '../components/AccessSchedule/AccessSchedule';
import { AccessScheduleTypes } from '../data/graphql/mutations/lock/types';
import { TPersonAccess } from '../data/graphql/queries/entities';
import { TIsPersonActive } from '../forms/Filters/FilterNodes/IsActiveStatusNode';
import { dateToDayTimeObject, dateToDaytime, isDateInRange } from './date.functions';
import { getAccessObject, parseUserSchedule } from './lock.functions';
import TypesUtils from './types.function';
import { PersonTypeCodeEnum } from '../data/graphql/queries/enums';

export function transformPersonActiveStatus(value: TIsPersonActive) {
  switch (value) {
    case 'active':
      return true;
    case 'inactive':
      return false;
    default:
      return undefined;
  }
}

export const isPersonAccessActive = (personAccess?: Pick<TPersonAccess, 'miscInfo'>) => {
  const access = personAccess?.miscInfo?.lockAccess;

  if (!access) {
    return false;
  } else if (access.yaleUserType !== 'LIMITED' && access.yaleUserType !== 'UNVERIFIED') {
    return true;
  }

  if (access.pin) {
    const { endDateTime, startDateTime } = getAccessObject(access.pin);
    return isDateInRange(new Date(), startDateTime, endDateTime);
  }

  if (access.limitingRule) {
    const { startTime, endTime } = access.limitingRule;
    return isDateInRange(new Date(), startTime, endTime);
  }

  return true;
};

export const getScheduleFromPersonAccess = (personAccess?: Pick<TPersonAccess, 'miscInfo' | 'personAccessTypeId'>) => {
  const access = personAccess?.miscInfo?.lockAccess;
  const schedule: TAccessScheduleFields = {
    accessType: personAccess?.personAccessTypeId === '4' ? 'pin' : 'app',
    accessScheduleType: AccessScheduleTypes.ALWAYS,
    startDate: new Date(),
    endDate: addDays(new Date(), 30),
    weekDays: [],
    startTime: {
      hours: 9,
      minutes: 0,
      dayTime: 'am',
    },
    endTime: {
      hours: 12,
      minutes: 0,
      dayTime: 'am',
    },
  };

  const pin = access?.pin;
  const rule = access?.limitingRule;
  const accessType = pin?.accessType || rule?.accessType;

  if (accessType) {
    schedule.accessScheduleType = accessType as AccessScheduleTypes;
  }

  if (rule?.accessType === 'RECURRING') {
    const { startDateTime, endDateTime, days } = parseUserSchedule(rule.schedule);

    schedule.startTime = dateToDayTimeObject(startDateTime);
    schedule.endTime = dateToDayTimeObject(endDateTime);
    schedule.weekDays = days;
  } else if (rule?.accessType === 'TEMPORARY') {
    const { startDateTime, endDateTime } = parseUserSchedule(rule.schedule);

    schedule.startDate = startDateTime;
    schedule.endDate = endDateTime;
  } else if (pin) {
    const pinAccess = getAccessObject(pin);

    if (pinAccess.startDateTime) {
      schedule.startDate = pinAccess.startDateTime;
    }

    if (pinAccess.endDateTime) {
      schedule.endDate = pinAccess.endDateTime;
    }

    if (pinAccess.days) {
      schedule.weekDays = pinAccess.days;
      schedule.startTime = dateToDaytime(pinAccess.startDateTime);
      schedule.endTime = dateToDaytime(pinAccess.endDateTime);
    }
  }

  return schedule;
};

export const getFullName = (person?: { firstName?: string; lastName?: string }) => {
  if (person?.firstName && person?.lastName) {
    return `${person.firstName} ${person.lastName}`;
  }
  return '';
};

export const getPersonTypeById = (personTypeId?: string | number) => {
  const id = Number(personTypeId);
  const personTypeCode = TypesUtils.person.codeById(id);

  const types: Record<PersonTypeCodeEnum, string> = {
    [PersonTypeCodeEnum.UNKNOWN]: 'Unknown',
    [PersonTypeCodeEnum.TENANT]: 'Tenant',
    [PersonTypeCodeEnum.RESIDENT]: 'Resident',
    [PersonTypeCodeEnum.VENDOR]: 'Vendor',
    [PersonTypeCodeEnum.EMPLOYEE]: 'Employee',
    [PersonTypeCodeEnum.CONTRACTOR]: 'Contractor',
    [PersonTypeCodeEnum.PROPERTY]: 'PROPERTY',
    [PersonTypeCodeEnum.GUEST]: 'Guest',
    [PersonTypeCodeEnum.BUILDING]: 'Building',
    [PersonTypeCodeEnum.SELF_GUIDED]: 'Self-Guided Tour',
    [PersonTypeCodeEnum.DEV_ADMIN]: 'Developer Admin',
  };

  return types[personTypeCode];
};
