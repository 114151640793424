import { useCallback } from 'react';
import {
  AccessScheduleTypes,
  TGrantFullAccessToLocksVariables,
  TGrantLimitedAccessToLocksVariables,
} from '../data/graphql/mutations/lock/types';
import useToast from '../hooks/useToast';
import { DeviceMaker, YaleUserType } from '../data/graphql/types';
import { scheduleToAccessParams } from '../functions/lock.functions';
import useServicePersonAccesses from '../hooks/data/useServicePersonAccesses';
import { TAccessType } from '../components/AccessSchedule/AccessSchedule';
import { GET_ASYNC_TRANSACTIONS } from '../data/graphql/queries/people';
import { client } from '../data/graphql';
import { capitalize } from '../functions';
import { PersonTypeCodeEnum, ResidentTypeCodeEnum } from '../data/graphql/queries/enums';
import Api from '../services/Api';
import { DeviceMakerEnum, YaleUserTypeEnum } from '../data/graphql/enums';

type TLockAccess = {
  accessLevel?: TAccessType;
  lockId: string;
  installedDeviceId: number;
  access?: {
    schedule?: string;
    accessType?: AccessScheduleTypes;
    deviceId?: string;
    endTime?: string;
    startTime?: string;
  };
};

type TCommonAreaAccess = {
  accessType: TAccessType;
  propertyId: string;
  startDate?: string;
  endDate?: string;
};

type TLockRemoveAccess = {
  installedDeviceId: number;
};

type FullYaleAccessData = {
  accesses: TGrantFullAccessToLocksVariables['input']['lockAccesses'];
  personType: TGrantFullAccessToLocksVariables['input']['personType'];
  residentType: TGrantFullAccessToLocksVariables['input']['residentType'];
};

type LimitedYaleAccessData = {
  accesses: TGrantLimitedAccessToLocksVariables['input']['lockAccessRules'];
  personType: TGrantLimitedAccessToLocksVariables['input']['personType'];
  residentType: TGrantLimitedAccessToLocksVariables['input']['residentType'];
};

type UpdateAccessesData = {
  accesses: TLockAccess[];
  personType: TGrantLimitedAccessToLocksVariables['input']['personType'];
  residentType: TGrantLimitedAccessToLocksVariables['input']['residentType'];
};

type LockAccessesProps = {
  personId: number;
  profileId: number;
  personType: PersonTypeCodeEnum;
};

const useLockAccesses = ({ personId, profileId, personType }: LockAccessesProps) => {
  const { showErrorToast, showInfoToast } = useToast();

  const { person, response: personAccessResponse } = useServicePersonAccesses({ personId });

  const yaleUserId = person?.miscInfo?.yaleLock?.userId || '';

  const handleYalePinsResponse = useCallback(
    (data: { failed: any[] } | undefined) => {
      if (data?.failed.length) {
        showErrorToast('Pin code access', `Some the pin codes has not been updated`);
      } else {
        showInfoToast('Pin code access', 'All pin codes have been submitted');
      }
    },
    [showErrorToast, showInfoToast],
  );

  const handleYaleLocksResponse = useCallback(
    (action: 'grant' | 'revoke', data: { failed: any[] } | undefined) => {
      const capitalizeAction = capitalize(action);
      const passiveAction = action === 'revoke' ? 'revoked' : 'granted';
      const title = `${capitalizeAction} unit accesses`;

      if (data?.failed?.length) {
        showErrorToast(title, `One or more access could not be ${passiveAction}`);
      } else {
        showInfoToast(title, `Accesses to units locks has been ${passiveAction}`);
      }
    },
    [showErrorToast, showInfoToast],
  );

  const groupAppAccesses = (accesses: TLockAccess[]) => {
    const limitedLockAccesses: TGrantLimitedAccessToLocksVariables['input']['lockAccessRules'] = [];
    const fullLockAccesses: TGrantFullAccessToLocksVariables['input']['lockAccesses'] = [];

    accesses.forEach(({ access }) => {
      if (!access?.deviceId) {
        return;
      }

      if (access.accessType === AccessScheduleTypes.ALWAYS) {
        fullLockAccesses.push({
          yaleDeviceId: access.deviceId,
          userType: YaleUserTypeEnum.USER,
        });
      } else {
        limitedLockAccesses.push({
          schedule: access.schedule || '',
          accessType: access.accessType,
          deviceId: access.deviceId || '',
          endTime: access.endTime || '',
          startTime: access.startTime || '',
        });
      }
    });

    return { limited: limitedLockAccesses, full: fullLockAccesses };
  };

  const grantFullYaleAccess = useCallback(
    async ({ accesses, personType, residentType }: FullYaleAccessData) => {
      if (!accesses.length) {
        return Promise.resolve([]);
      }

      const failedLockYaleIds: string[] = [];

      try {
        const response = await Api.lock.grantFullAccessToLocks({
          userId: yaleUserId,
          deviceMaker: DeviceMakerEnum.YALE,
          lockAccesses: accesses,
          personType,
          residentType,
        });

        handleYaleLocksResponse('grant', response);
        response?.failed.forEach(({ yaleDeviceId }) => failedLockYaleIds.push(yaleDeviceId));
      } catch (e) {
        accesses.forEach(({ yaleDeviceId }) => failedLockYaleIds.push(yaleDeviceId));
      }

      return failedLockYaleIds;
    },
    [yaleUserId, handleYaleLocksResponse],
  );

  const grantFullIglooAccess = useCallback(
    async ({ accesses, personType, residentType }: FullYaleAccessData) => {
      if (!accesses.length) {
        return Promise.resolve([]);
      }

      const failedLockYaleIds: string[] = [];
      const startDate = new Date().toISOString();

      try {
        //     const response = await Api.lock.grantFullLockAccess({
        //       personId,
        //       personType: personType as PersonTypeCodeEnum,
        //       deviceMaker: DeviceMakerEnum.IGLOO,
        //       residentType: residentType as ResidentTypeCodeEnum,
        // igloo: {
        //   lockAccesses: {
        //     startDate,
        //     installedDeviceId: number;
        //   }[]
        // }
        //     });
        // handleYaleLocksResponse('grant', response);
        // response?.failed.forEach(({ yaleDeviceId }) => failedLockYaleIds.push(yaleDeviceId));
      } catch (e) {
        accesses.forEach(({ yaleDeviceId }) => failedLockYaleIds.push(yaleDeviceId));
      }

      return failedLockYaleIds;
    },
    [yaleUserId, handleYaleLocksResponse],
  );

  const grantLimitedYaleAccess = useCallback(
    async ({ accesses, residentType, personType }: LimitedYaleAccessData) => {
      if (!accesses.length) {
        return Promise.resolve([]);
      }

      const failedLockYaleIds: string[] = [];

      try {
        const response = await Api.lock.grantLimitedAccessToLocks({
          residentType,
          personType,
          lockAccessRules: accesses,
          userId: yaleUserId,
          deviceMaker: DeviceMaker.YALE,
        });

        handleYaleLocksResponse('grant', response);
        response?.failed.forEach(({ yaleDeviceId }) => failedLockYaleIds.push(yaleDeviceId));
      } catch (e) {
        accesses.forEach(({ deviceId }) => failedLockYaleIds.push(deviceId));
      }

      return failedLockYaleIds;
    },
    [yaleUserId, handleYaleLocksResponse],
  );

  const updatePinAccesses = useCallback(
    async (accesses: TLockAccess[], unverifiedUserYaleUserId?: string) => {
      // if (!accesses.length) {
      //   return Promise.resolve();
      // }
      // const locksToSet = accesses.map(({ lockId, access, accessLevel }) => {
      //   const yaleUserType =
      //     accessLevel === 'pin' ? YaleUserType.UNVERIFIED : access?.schedule ? YaleUserType.LIMITED : YaleUserType.USER;
      //   const pinAccessData = scheduleToAccessParams(access?.schedule);
      //   return {
      //     yaleUserType,
      //     deviceId: lockId,
      //     ...pinAccessData,
      //   };
      // });
      // const response = await Api.lock.setLockPins({
      //   locksToSet,
      //   userId: unverifiedUserYaleUserId ?? yaleUserId,
      //   deviceMaker: DeviceMaker.YALE,
      //   personType: personType,
      //   residentType: ResidentTypeCodeEnum.NOT_A_RESIDENT,
      // });
      // handleYalePinsResponse(response);
      // return response;
    },
    [yaleUserId, personType, handleYalePinsResponse],
  );

  const updateAppAccesses = useCallback(
    async ({ accesses, personType, residentType }: UpdateAccessesData): Promise<string[]> => {
      if (!accesses.length) {
        return Promise.resolve([]);
      }

      const groupedAccesses = groupAppAccesses(accesses);

      const [fullAccessResponse, limitedAccessResponse] = await Promise.all([
        grantFullYaleAccess({ accesses: groupedAccesses.full, personType, residentType }),
        grantLimitedYaleAccess({
          accesses: groupedAccesses.limited,
          personType,
          residentType,
        }),
      ]);

      return [...fullAccessResponse, ...limitedAccessResponse];
    },
    [grantFullYaleAccess, grantLimitedYaleAccess],
  );

  const revokeLockAccesses = useCallback(
    async (accesses: TLockRemoveAccess[] = []) => {
      if (!accesses.length || !profileId) {
        return Promise.resolve(null);
      }

      const installedDeviceIds = accesses.map(({ installedDeviceId }) => installedDeviceId);

      const response = await Api.lock.revokeAccessToLocks({
        personProfileId: Number(profileId),
        installedDeviceIds,
        deviceMaker: DeviceMaker.YALE,
      });

      client.refetchQueries({
        include: [GET_ASYNC_TRANSACTIONS],
      });

      handleYaleLocksResponse('revoke', response);

      return response;
    },
    [profileId, handleYaleLocksResponse],
  );

  const grantCommonAreaAccesses = useCallback(
    async (access: TCommonAreaAccess | null, unverifiedPersonId?: number): Promise<true | never> => {
      if (!access) {
        return true;
      }

      let personId = unverifiedPersonId || 0;

      if (!personId && person?.id) {
        personId = Number(person.id);
      }

      const buildGrantRequest = ({ propertyId, startDate, endDate, accessType }: TCommonAreaAccess) =>
        Api.lock.grantCommonAreaAccess({
          personId,
          propertyId: Number(propertyId),
          effectiveFrom: startDate,
          effectiveTo: endDate,
          deviceMaker: DeviceMakerEnum.BRIVO,
          enableAppAccess: accessType === 'app',
          personType: personType,
          residentType: ResidentTypeCodeEnum.NOT_A_RESIDENT,
        });

      const response = await Promise.allSettled([buildGrantRequest(access)]);

      const numberOfFailedRequests = response.filter(({ status }) => status === 'rejected').length;

      if (numberOfFailedRequests === response.length) {
        showErrorToast('Common area access', 'Failed to grant common areas access');
        throw new Error('Failed to grant common areas access', { cause: 'requestFailure' });
      } else if (numberOfFailedRequests > 0) {
        showErrorToast('Common area access', 'Failed to grant access to some common areas');
        throw new Error('Failed to grant access to some common areass', {
          cause: 'partialRequestFailure',
        });
      }

      showInfoToast('Common area access', 'Common areas access has been granted');

      return true;
    },
    [person?.id, personType, showErrorToast, showInfoToast],
  );

  const revokeCommonAreaAccesses = useCallback(async () => {
    if (!profileId) {
      return Promise.resolve(null);
    }

    try {
      await Api.lock.revokeCommonAreaAccess({
        personProfileId: Number(profileId),
        deviceMaker: DeviceMakerEnum.BRIVO,
      });

      showInfoToast('Common areas access', 'Accesses to common areas have been revoked');
    } catch (e) {
      showErrorToast('Common areas access', 'Failed to revoke common areas accesses');
    }
  }, [profileId, showErrorToast, showInfoToast]);

  return {
    revokeLockAccesses,
    revokeCommonAreaAccesses,
    grantCommonAreaAccesses,
    updatePinAccesses,
    updateAppAccesses,
    refreshPersonAccesses: personAccessResponse.refetch,
  };
};

export default useLockAccesses;
