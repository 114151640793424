import { TAccessScheduleFields } from '../../../components/AccessSchedule/AccessSchedule';
import { AccessScheduleTypes } from '../../../data/graphql/mutations/lock/types';
import { TPersonAccess } from '../../../data/graphql/queries/entities';
import BrivoPersonAccessUtils from './BrivoServiceAccessUtils';
import IglooPersonAccessUtils from './IglooServiceAccessUtils';
import YalePersonAccessUtils from './YaleServiceAccessUtils';

const ServiceAccessUtils = {
  getScheduleType: (miscInfo: TPersonAccess['miscInfo']): AccessScheduleTypes => {
    if (miscInfo?.lockAccess) {
      return YalePersonAccessUtils.getScheduleType(miscInfo);
    } else if (miscInfo?.iglooLockAccess) {
      return IglooPersonAccessUtils.getScheduleType(miscInfo);
    } else if (miscInfo?.commonAreaLockAccess) {
      return BrivoPersonAccessUtils.getScheduleType(miscInfo);
    }

    return AccessScheduleTypes.UNKNOWN;
  },
  getServiceAccessData: (
    data: Pick<TPersonAccess, 'miscInfo' | 'personAccessTypeId'>,
  ): TAccessScheduleFields | null => {
    const { miscInfo } = data;

    if (miscInfo?.lockAccess) {
      return YalePersonAccessUtils.getServiceAccessData(data);
    } else if (miscInfo?.iglooLockAccess) {
      return IglooPersonAccessUtils.getServiceAccessData(data);
    } else if (miscInfo?.commonAreaLockAccess) {
      return BrivoPersonAccessUtils.getServiceAccessData(data);
    }

    return null;
  },
};

export default ServiceAccessUtils;
