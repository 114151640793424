export enum PersonTypeCodeEnum {
  UNKNOWN = 'NC',
  TENANT = 'T',
  RESIDENT = 'R',
  VENDOR = 'V',
  EMPLOYEE = 'E',
  CONTRACTOR = 'C',
  PROPERTY = 'P',
  GUEST = 'G',
  BUILDING = 'B',
  SELF_GUIDED = 'SGT',
  DEV_ADMIN = 'DA',
}

export enum AccessTypeCodeEnum {
  UNKNOWN = 'NC',
  OWNER = 'O',
  RESIDENT = 'R',
  GUEST = 'G',
  PIN_CODE = 'P',
  PROPERTY_ACCOUNT = 'PA',
  BUILDING_ACCOUNT = 'BA',
}

export enum ResidentTypeCodeEnum {
  UNKNOWN = 'NC',
  GUARANTOR = 'G',
  HEAD_OF_HOUSEHOLD = 'HOH',
  CO_HEAD_OF_HOUSEHOLD = 'CHOH',
  ADULT_RESIDENT = 'AR',
  CHILD_RESIDENT = 'CR',
  NOT_A_RESIDENT = 'NAR',
}

export enum LeaseTypeCodeEnum {
  UNKNOWN = 'NC',
  FIRST_LEASE = 'FL',
  MOVED_OUT = 'MO',
  NOTICE_GIVEN = 'NG',
  RENEWAL = 'R',
}

export enum SecretTypeCodeEnum {
  UNKNOWN = 'NC',
  ACCESS_TOKEN = 'AT',
  PASSWORD = 'PW',
  MASTER_PIN = 'MPC',
  REFRESH_TOKEN = 'RT',
  EMAIL_PASSWORD = 'BAEP',
}

export enum UnitTypeCodeEnum {
  UNKNOWN = 'NC',
  APARTMENT = 'A',
  SHARED = 'S',
  MODEL = 'M',
}

export enum ServiceTicketStatusCodeEnum {
  UNKNOWN = 'NC',
  CANCELED = 'CAN',
  COMPLETE = 'COM',
  IN_PROGRESS = 'IP',
  ON_HOLD = 'OH',
}

export enum DeviceClassTypeCodeEnum {
  UNKNOWN = 'NC',
  LOCK = 'L',
  BRIDGE = 'LB',
  THERMOSTAT = 'T',
  DOOR_SENSE = 'DS',
}

export enum DeviceTypeCodeEnum {
  NEST_E = 'NT/NTE',
  YALE_622 = 'YL/YRC/D622',
  YALE_BRIDGE = 'YLB/august-connect',
  YALE_DS = 'YL/DS',
  YALE_ASSURE_2 = 'YL/YRD 410/420/430/450',
  BRIVO = 'B/B-BSPKF-B',
  HONEYWELL_T6 = 'HT/T6',
  IGLOO_NB = 'IL/NB',
}

export enum DeviceManufacturerCodeEnum {
  UNKNOWN = 'NC',
  YALE = 'Y',
  NEST = 'N',
  BRIVO = 'B',
  HONEYWELL = 'H',
}

export enum DeviceLocationCodeEnum {
  UNKNOWN = 'NC',
  FRONT_DOOR = 'FD',
  BACK_DOOR = 'BD',
  LIVING_ROOM = 'LR',
  LAUNDRY_ROOM = 'LAU',
  MAIN_BEDROOM = 'MBR',
  BEDROOM_1 = 'BR1',
  BEDROOM_2 = 'BR2',
  BEDROOM_3 = 'BR3',
  BEDROOM_4 = 'BR4',
  KITCHEN = 'KT',
  MAIN_BATHROOM = 'MBA',
  BATHROOM_1 = 'BA1',
  BATHROOM_2 = 'BA2',
  HALL_BATH = 'HBA',
  DETACHED_GARAGE = 'DGAR',
  ATTACHED_GARAGE = 'AGAR',
  PARKING_GARAGE = 'PGAR',
  BUILDING_ENTRANCE = 'BE',
  MEN_BATHROOM = 'MB',
  WOMEN_BATHROOM = 'WB',
}

export enum DepartmentCodeEnum {
  MGR = 'MGR',
  VPO = 'VPO',
  APTHUB_MGR = 'APTHUB_MGR',
}

export enum TenantSecretTypeCodeEnum {
  API_KEY = 'AK',
}

export enum DeviceManufacturerNameEnum {
  'YALE' = 'YALE',
  'NEST' = 'NEST',
  'BRIVO' = 'BRIVO',
  'HONEYWELL' = 'YALE',
}

export enum SecretTypeNameEnum {
  API_KEY = 'API_KEY',
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  PASSWORD = 'PASSWORD',
  MASTER_PIN_CODE = 'MASTER_PIN_CODE',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  BUILDING_ACCOUNT_EMAIL_PASSWORD = 'BUILDING_ACCOUNT_EMAIL_PASSWORD',
}
