import { addHours } from 'date-fns';
import { TAccessScheduleFields } from '../../../components/AccessSchedule/AccessSchedule';
import { AccessScheduleTypes } from '../../../data/graphql/mutations/lock/types';
import { TPersonAccess } from '../../../data/graphql/queries/entities';
import { AccessTypeCodeEnum } from '../../../data/graphql/queries/enums';
import TypesUtils from '../../../functions/types.function';
import { dateToDayTimeObject } from '../../../functions';

const BrivoServiceAccessUtils = {
  getScheduleType: (miscInfo: TPersonAccess['miscInfo']): AccessScheduleTypes => {
    const access = miscInfo?.commonAreaLockAccess;

    if (!access) {
      return AccessScheduleTypes.UNKNOWN;
    }

    return access.effectiveTo ? AccessScheduleTypes.TEMPORARY : AccessScheduleTypes.ALWAYS;
  },

  getServiceAccessData: (
    data: Pick<TPersonAccess, 'miscInfo' | 'personAccessTypeId'>,
  ): TAccessScheduleFields | null => {
    const { miscInfo, personAccessTypeId } = data;

    const access = miscInfo?.commonAreaLockAccess;
    const accessType = TypesUtils.access.codeById(Number(personAccessTypeId));
    const isPinOnlyAccess = accessType === AccessTypeCodeEnum.PIN_CODE;

    if (!access) {
      return null;
    }

    const effectiveTo = access?.effectiveTo;
    const effectiveFrom = access?.effectiveFrom;
    const accessScheduleType = BrivoServiceAccessUtils.getScheduleType(miscInfo);

    const startDate = effectiveFrom ? new Date(effectiveFrom) : new Date();
    const endDate = effectiveTo ? new Date(effectiveTo) : addHours(startDate, 1);

    return {
      accessType: isPinOnlyAccess ? 'pin' : 'app',
      accessScheduleType,
      startDate: startDate,
      endDate: endDate,
      weekDays: [],
      startTime: dateToDayTimeObject(startDate),
      endTime: dateToDayTimeObject(addHours(startDate, 1)),
    };
  },
};

export default BrivoServiceAccessUtils;
