import { client } from '../../data/graphql';
import { DeviceMakerEnum, YaleUserTypeEnum } from '../../data/graphql/enums';
import { AccessTypeCodeEnum, PersonTypeCodeEnum, ResidentTypeCodeEnum } from '../../data/graphql/queries/enums';
import { TGetEmployeeResponse } from '../../data/graphql/queries/people/types';
import { SEND_APP_INVITE } from '../../data/graphql/queries/utility';
import { TSendAppInviteResponse, TSendAppInviteVariables } from '../../data/graphql/queries/utility/types';
import { TServiceCommonAreaAccess, TServiceTaskAccess } from '../../layouts/VendorUserLayout/useScheduleAccessPoints';
import AccessService from '../../services/AccessSerivces';
import { UnifiedAccessData } from '../../services/AccessSerivces/types';
import NumberUtils from '../../utils/NumberUtils';
import { UnifiedAccessChanges } from './useStaffOverview';

const StaffOverviewUtils = {
  createUnifiedPerson: (
    personProfileId?: string,
    employee?: TGetEmployeeResponse['transactionalDb']['employee'],
  ): UnifiedAccessData['person'] => {
    const yaleUserId = employee?.person?.miscInfo?.yaleLock?.userId;

    return {
      phone: employee?.person?.mobilePhone || '',
      lastName: employee?.person?.lastName || '',
      firstName: employee?.person?.firstName || '',
      email: employee?.workEmail || '',
      personId: employee?.personId ? Number(employee.personId) : null,
      personType: PersonTypeCodeEnum.EMPLOYEE,
      partnerUserId: yaleUserId || null,
      personProfileId: personProfileId ? Number(personProfileId) : null,
      partnerUserType: YaleUserTypeEnum.USER,
      residentType: ResidentTypeCodeEnum.NOT_A_RESIDENT,
    };
  },

  createUnifiedAccessData: (
    person: UnifiedAccessData['person'],
    deviceMaker: DeviceMakerEnum,
    value?: TServiceTaskAccess[] | TServiceCommonAreaAccess[],
  ): UnifiedAccessData => {
    const createAccess = (item: TServiceTaskAccess | TServiceCommonAreaAccess) => {
      const result: UnifiedAccessData['accesses'][number] = {
        device: {
          partnerLockId: '',
          installedDeviceId: 0,
        },
        access: {
          days: null,
          endDateTime: null,
          startDateTime: new Date(),

          currentPin: null,
          prevPin: null,
          propertyId: 0,

          scheduleType: 'ALWAYS',
          accessType: AccessTypeCodeEnum.GUEST,
          personAccessId: null,
          notes: '',
        },
      };

      if (item.deviceMaker === DeviceMakerEnum.BRIVO) {
        const accessItem = item as TServiceCommonAreaAccess;
        result.access.propertyId = Number(accessItem.propertyId);
      } else {
        const accessItem = item as TServiceTaskAccess;

        result.device.partnerLockId = accessItem.lockId;
        result.device.installedDeviceId = accessItem.installedDeviceId;
        result.access.personAccessId = null;
      }

      return result;
    };

    return {
      person,
      deviceMaker,
      accesses: value?.map<UnifiedAccessData['accesses'][number]>(createAccess) || [],
    };
  },

  revokeAccesses: async (data: { igloo: UnifiedAccessData; yale: UnifiedAccessData; brivo: UnifiedAccessData }) => {
    const [iglooAccesses, yaleAccesses, brivoAccesses] = await Promise.allSettled([
      AccessService.revokeAccess(data.igloo),
      AccessService.revokeAccess(data.yale),
      AccessService.revokeAccess(data.brivo),
    ]);

    const iglooFailedLocks = iglooAccesses.status === 'fulfilled' ? iglooAccesses.value.failed : [];
    const yaleFailedLocks = yaleAccesses.status === 'fulfilled' ? yaleAccesses.value.failed : [];
    const brivoFailedLocks = brivoAccesses.status === 'fulfilled' ? brivoAccesses.value.failed : [];

    return [...iglooFailedLocks, ...yaleFailedLocks, ...brivoFailedLocks];
  },

  grantAccesses: async (data: { igloo: UnifiedAccessData; yale: UnifiedAccessData; brivo: UnifiedAccessData }) => {
    const [yaleAccesses, iglooAccesses, brivoAccesses] = await Promise.allSettled([
      AccessService.grantFullAccess(data.yale),
      AccessService.grantFullAccess(data.igloo),
      AccessService.grantFullAccess(data.brivo),
    ]);

    const yaleFailedLocks = yaleAccesses.status === 'fulfilled' ? yaleAccesses.value : [];
    const iglooFailedLocks = iglooAccesses.status === 'fulfilled' ? iglooAccesses.value : [];
    const brivoFailedLocks = brivoAccesses.status === 'fulfilled' ? brivoAccesses.value : [];

    const failedLocks = [...yaleFailedLocks, ...iglooFailedLocks];

    const [yalePinAccesses, iglooPinAccesses] = await Promise.allSettled([
      AccessService.setPins({
        ...data.yale,
        accesses: data.yale.accesses.filter(({ device }) => !failedLocks.includes(device.partnerLockId)),
      }),
      AccessService.setPins({
        ...data.igloo,
        accesses: data.igloo.accesses.filter(
          ({ device }) => !failedLocks.includes(device.installedDeviceId.toString()),
        ),
      }),
    ]);

    const yaleFailedPins = yalePinAccesses.status === 'fulfilled' ? yalePinAccesses.value.failed : [];
    const iglooFailedPins = iglooPinAccesses.status === 'fulfilled' ? iglooPinAccesses.value.failed : [];

    const failedPins = [...yaleFailedPins, ...iglooFailedPins];

    return [...failedLocks, ...failedPins, ...brivoFailedLocks];
  },

  inviteEmployee: async (
    employee: { firstName: string; lastName: string; phone: string; email: string },
    unifiedAccesses: UnifiedAccessChanges,
  ) => {
    const unitAccesses = [...unifiedAccesses.requested.igloo.accesses, ...unifiedAccesses.requested.yale.accesses];
    const commonAreaAccess = unifiedAccesses.requested.brivo.accesses;

    const payload: TSendAppInviteVariables = {
      input: {
        sender: {
          lastName: 'Platform',
          firstName: 'Apthub',
        },
        invitee: {
          email: employee.email,
          firstName: employee.firstName,
          lastName: employee.lastName,
          mobilePhone: employee.phone,
          personType: 'EMPLOYEE',
        },
        requestedThermostatAccesses: [],
        requestedLockAccesses: unitAccesses.map(({ device }) => ({
          installedDeviceId: device.installedDeviceId,
          schedule: '',
        })),
      },
    };

    if (commonAreaAccess.length) {
      payload.input.requestedCommonAreaLockAccess = {
        propertyId: NumberUtils.safeParse(commonAreaAccess[0].access.propertyId),
      };
    }

    const response = await client.mutate<TSendAppInviteResponse, TSendAppInviteVariables>({
      mutation: SEND_APP_INVITE,
      variables: payload,
    });

    return response.data?.utility.sendAppInvitation.success;
  },
};

export default StaffOverviewUtils;
