import { client } from '../../data/graphql';
import {
  GRANT_COMMON_AREA_ACCESSES,
  GRANT_FULL_ACCESS_TO_LOCKS,
  GRANT_FULL_LOCK_ACCESS,
  GRANT_LIMITED_ACCESS_TO_LOCKS,
  REVOKE_ACCESSES_TO_LOCKS,
  REVOKE_COMMON_AREA_ACCESSES,
  SET_LOCK_PINS,
  SET_PINS,
} from '../../data/graphql/mutations/lock';
import {
  TGrantCommonAreaAccessResponse,
  TGrantCommonAreaAccessVariables,
  TGrantFullAccessToLocksResponse,
  TGrantFullAccessToLocksVariables,
  TGrantFullLockAccessResponse,
  TGrantFullLockAccessVariables,
  TGrantLimitedAccessToLocksResponse,
  TGrantLimitedAccessToLocksVariables,
  TRevokeAccessToLocksResponse,
  TRevokeAccessToLocksVariables,
  TRevokeCommonAreaAccessResponse,
  TRevokeCommonAreaAccessVariables,
  TSetLockPinsResponse,
  TSetLockPinsVariables,
  TSetPinsResponse,
  TSetPinsVariables,
} from '../../data/graphql/mutations/lock/types';

const YaleLockApi = {
  grantLimitedAccessToLocks: async (input: TGrantLimitedAccessToLocksVariables['input']) => {
    const response = await client.mutate<TGrantLimitedAccessToLocksResponse, TGrantLimitedAccessToLocksVariables>({
      mutation: GRANT_LIMITED_ACCESS_TO_LOCKS,
      variables: {
        input,
      },
    });

    return response.data?.lock?.grantLimitedAccessToLocks;
  },

  grantFullLockAccess: async (input: TGrantFullLockAccessVariables['input']) => {
    const response = await client.mutate<TGrantFullLockAccessResponse, TGrantFullLockAccessVariables>({
      mutation: GRANT_FULL_LOCK_ACCESS,
      variables: {
        input,
      },
    });

    return response.data?.lock?.grantFullLockAccess;
  },

  grantFullAccessToLocks: async (input: TGrantFullAccessToLocksVariables['input']) => {
    const response = await client.mutate<TGrantFullAccessToLocksResponse, TGrantFullAccessToLocksVariables>({
      mutation: GRANT_FULL_ACCESS_TO_LOCKS,
      variables: {
        input,
      },
    });

    return response.data?.lock?.grantFullAccessToLocks;
  },

  revokeAccessToLocks: async (input: TRevokeAccessToLocksVariables['input']) => {
    const response = await client.mutate<TRevokeAccessToLocksResponse, TRevokeAccessToLocksVariables>({
      mutation: REVOKE_ACCESSES_TO_LOCKS,
      variables: {
        input,
      },
    });

    const data = response.data?.lock?.revokeAccessToSelectedLocks;

    return {
      failed: data?.failed || [],
      revoked: data?.revoked || [],
    };
  },

  setLockPins: async (input: TSetLockPinsVariables['input']) => {
    const response = await client.mutate<TSetLockPinsResponse, TSetLockPinsVariables>({
      mutation: SET_LOCK_PINS,
      variables: {
        input,
      },
    });

    return response.data?.lock?.setLockPins;
  },

  setPins: async (input: TSetPinsVariables['input']) => {
    const response = await client.mutate<TSetPinsResponse, TSetPinsVariables>({
      mutation: SET_PINS,
      variables: {
        input,
      },
    });

    return response.data?.lock?.setPins;
  },

  grantCommonAreaAccess: async (input: TGrantCommonAreaAccessVariables['input']) => {
    const response = await client.mutate<TGrantCommonAreaAccessResponse, TGrantCommonAreaAccessVariables>({
      mutation: GRANT_COMMON_AREA_ACCESSES,
      variables: {
        input,
      },
    });

    return response.data?.lock?.grantAccessToCommonAreaLocks;
  },

  revokeCommonAreaAccess: async (input: TRevokeCommonAreaAccessVariables['input']) => {
    const response = await client.mutate<TRevokeCommonAreaAccessResponse, TRevokeCommonAreaAccessVariables>({
      mutation: REVOKE_COMMON_AREA_ACCESSES,
      variables: {
        input,
      },
    });

    return response.data?.lock?.revokeAccessFromCommonAreaLocks;
  },
};

export default YaleLockApi;
